import i18n from '@/i18n'
import { left } from '@antv/x6/lib/registry/port-layout/line';
const baseChartOptions = {
    // 不同类型图表的配置
    PIE: {
        tooltip: {
            formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
        },
        legend: {
            type: "scroll",
            orient: "vertical",
            align: "left",
            right: "0",
            top: "60%",
            width: "20%",
            height: "150",
            textStyle: {
                width: 150,
                overflow: "breakAll",
                rich: {
                    title: {
                        align: "left",
                    },
                    value: {
                        align: "right",
                    },
                },
            },
            tooltip: {
                show: true,
            },
        },
        series: [
            {
                left: 'center',
                top: -50,
                type: "pie",
                minAngle: 5,
                label: {
                    show: true,
                    position: 'outside',
                    formatter: "{b}: {c}\n{d}%",
                    width: 100,
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 'normal',
                    },
                },
                labelLine: {
                    show: true,
                },
                itemStyle: {
                    borderColor: "#fff",
                    borderWidth: 2,
                },
            },
        ],
    },
    RADAR: {
        tooltip: {},
        series: [
            {
                type: "radar",
                data: [
                    {
                        value: [],
                    },
                ],
            },
        ],
        radar: {
            indicator: [],
        },
    },
    BAR: {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            right: "0",
            icon: "circle",
            top: "30%",
        },
        dataZoom: [
            {
                show: true,
                start: 0,
                endValue: 4,
                filterMode: "none",
                brushSelect: false,
                handleSize: 0,
                height: 10
            }, {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseWheel: true,
                preventDefaultMouseMove: false,
            }
        ],
        series: [
            {
                type: "bar",
                areaStyle: {
                    color: "rgba(222, 238, 250, 1)",
                },
                itemStyle: {
                    color: "rgba(222, 238, 250, 1)",
                },
                barWidth: 20,
            },
        ],
        xAxis: {
            type: "category",
            boundaryGap: ["20%", "10%"],
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            splitLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            axisTick: {
                lineStyle: {
                    color: "#CCCCCC",
                },
                alignWithLabel: true,
                interval: "0",
            },
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: 0,
                formatter: function (value) {
                    let texts = value;
                    if (texts.length > 10) {
                        // 限制长度自设
                        texts = texts.substr(0, 10) + "...";
                    }
                    return texts;
                },
            },
            // 这里触发事件为true,否则x轴无法响应事件
            triggerEvent: true,
        },
        yAxis: {
            name: i18n.t('dataChart.Quantity'),
            type: "value",
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            minInterval: 1,
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
            },
        },
        grid: {
            top: "10%",
            right: "10%",
            bottom: "17%",
        },
    },
    LINE: {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            right: "0",
            icon: "circle",
            top: "30%",
        },
        dataZoom: [
            {
                show: true,
                start: 0,
                endValue: 4,
                filterMode: "none",
                brushSelect: false,
                handleSize: 0,
                height: 10
            }, {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseWheel: true,
                preventDefaultMouseMove: false,
            }
        ],
        series: [
            {
                type: "line",
                areaStyle: {
                    color: "rgba(222, 238, 250, 1)",
                },
            },
        ],
        xAxis: {
            type: "category",
            boundaryGap: false,
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            splitLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            axisTick: {
                lineStyle: {
                    color: "#CCCCCC",
                },
                alignWithLabel: true,
                interval: "0",
            },
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: 0,
                formatter: function (value) {
                    let texts = value;
                    if (texts.length > 10) {
                        // 限制长度自设
                        texts = texts.substr(0, 10) + "...";
                    }
                    return texts;
                },
            },
            // 这里触发事件为true,否则x轴无法响应事件
            triggerEvent: true,
        },
        yAxis: {
            name: i18n.t('dataChart.Quantity'),
            type: "value",
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            minInterval: 1,
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: "0",
            },
        },
        grid: {
            top: "10%",
            right: "10%",
            bottom: "17%",
        },
    },
    STATUS_DURATION: {
        tooltip: {
            trigger: "item",
            formatter: function (params) {
                let my_time = parseInt(params.value);
                let days = my_time / 1000 / 60 / 60 / 24;
                let daysRound = Math.floor(days);
                let hours = my_time / 1000 / 60 / 60 - 24 * daysRound;
                let hoursRound = Math.floor(hours);
                let minutes =
                    my_time / 1000 / 60 - 24 * 60 * daysRound - 60 * hoursRound;
                let minutesRound = Math.floor(minutes);
                let seconds = parseFloat(
                    my_time / 1000 -
                    24 * 60 * 60 * daysRound -
                    60 * 60 * hoursRound -
                    60 * minutesRound
                );
                seconds = Math.floor(seconds * 10) / 10;
                let time = "";
                if (daysRound) {
                    time =
                        daysRound + "天" + hoursRound + "时" + minutesRound + "分";
                } else if (!daysRound && hoursRound) {
                    time = hoursRound + "时" + minutesRound + "分";
                } else if (!daysRound && !hoursRound && minutesRound) {
                    time = minutesRound + "分";
                    if (seconds) {
                        time += Math.floor(seconds) + "秒";
                    }
                } else if (
                    !daysRound &&
                    !hoursRound &&
                    !minutesRound &&
                    seconds
                ) {
                    time = seconds + "秒";
                }
                return `${params.name}: ${time}`;
            },
        },
        legend: {
            orient: "vertical",
            right: "0",
            icon: "circle",
            top: "30%",
        },
        dataZoom: [
            {
                show: true,
                start: 0,
                endValue: 4,
                filterMode: "none",
                brushSelect: false,
                handleSize: 0,
                height: 10
            }, {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseWheel: true,
                preventDefaultMouseMove: false,
            }
        ],
        series: [
            {
                type: "line",
                areaStyle: {
                    color: "rgba(222, 238, 250, 1)",
                },
            },
        ],
        xAxis: {
            type: "category",
            boundaryGap: false,
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            splitLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            axisTick: {
                lineStyle: {
                    color: "#CCCCCC",
                },
                alignWithLabel: true,
                interval: "0",
            },
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: 0,
                formatter: function (value) {
                    let texts = value;
                    if (texts.length > 10) {
                        // 限制长度自设
                        texts = texts.substr(0, 10) + "...";
                    }
                    return texts;
                },
            },
            // 这里触发事件为true,否则x轴无法响应事件
            triggerEvent: true,
        },
        yAxis: {
            name: i18n.t('dataChart.Quantity'),
            type: "value",
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            minInterval: 86400000,
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: "0",
                formatter: function (value) {
                    let my_time = parseInt(value);
                    let days = my_time / 1000 / 60 / 60 / 24;
                    let daysRound = Math.floor(days);
                    let time = "";
                    if (daysRound) {
                        time = daysRound + "天";
                    } else {
                        time = "0天";
                    }
                    // return time;
                    const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
                    if (reg.test(time)) {
                        return time.substring(0, 4) + "\n" + time.substring(4);
                    } else {
                        let a =
                            time.length > 4
                                ? time.substring(0, 4) + "-\n" + time.substring(4)
                                : time.substring(0, 4);
                        return a;
                    }
                },
            },
        },
        grid: {
            top: "10%",
            // left: "15%",
            // right: "32%",
            bottom: "17%",
        },
    },
    STATUS_TIMES: {
        tooltip: {
            trigger: "item",
        },
        legend: {
            orient: "vertical",
            right: "0",
            icon: "circle",
            top: "30%",
        },
        dataZoom: [
            {
                show: true,
                start: 0,
                endValue: 4,
                filterMode: "none",
                brushSelect: false,
                handleSize: 0,
                height: 10
            }, {
                type: 'inside',
                zoomOnMouseWheel: false,
                moveOnMouseWheel: true,
                preventDefaultMouseMove: false,
            }
        ],
        series: [
            {
                type: "line",
                areaStyle: {
                    color: "rgba(222, 238, 250, 1)",
                },
            },
        ],
        xAxis: {
            type: "category",
            boundaryGap: false,
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            splitLine: {
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            axisTick: {
                lineStyle: {
                    color: "#CCCCCC",
                },
                alignWithLabel: true,
                interval: "0",
            },
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: 0,
                formatter: function (value) {
                    let texts = value;
                    if (texts.length > 10) {
                        // 限制长度自设
                        texts = texts.substr(0, 10) + "...";
                    }
                    return texts;
                },
            },
            // 这里触发事件为true,否则x轴无法响应事件
            triggerEvent: true,
        },
        yAxis: {
            name: i18n.t('dataChart.Quantity'),
            type: "value",
            nameTextStyle: {
                color: "rgba(128, 128, 128, 1)",
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: "#EBEEF5",
                },
            },
            minInterval: 1,
            axisLabel: {
                color: "rgba(128, 128, 128, 1)",
                interval: "0",
            },
        },
        grid: {
            top: "10%",
            // right: "32%",
            bottom: "17%",
        },
    },
}
export { baseChartOptions }