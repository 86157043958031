import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'


// 新建dashboard
export function add_dashboard(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard`,

    method: "post",
    data,
  });
}
// 获取dashboard下拉框列表
export function get_dashboard_select(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard`,

    method: "get",
  });
}
// 获取dashboard详情
export function get_dashboard_detail(projectId, dashBoardId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}`,

    method: "get",
  });
}
// 更新dashboard
export function edit_dashboard(projectId, dashBoardId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}`,

    method: "put",
    data,
  });
}
// 删除dashboard
export function delete_dashboard(projectId, dashBoardId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}`,

    method: "delete",
  });
}
// 创建图表
export function add_chart(projectId, dashBoardId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart`,

    method: "post",
    data,
  });
}
// get二维覆盖度报表
export function add_t_c(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/twoDimensionCoverage?pageSize=20&pageNumber=${params.pageData.pageNumber}`,
    method: "post",
    data: params.data,
  });
}
// 导出二维报表
export function exportTwoD(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/twoDimensionCoverage/excel?pageSize=20&pageNumber=${params.pageData.pageNumber}`,
    method: "post",
    data: params.data,
  });
}
// 更新图表
export function edit_chart(projectId, dashBoardId, chartId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart/${chartId}`,

    method: "put",
    data,
  });
}
// 创建图表
export function add_vmodel_chart(projectId, dashBoardId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart/vmodel`,

    method: "post",
    data,
  });
}
// 更新图表
export function edit_vmodel_chart(projectId, dashBoardId, chartId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart/vmodel/${chartId}`,

    method: "put",
    data,
  });
}
// 获取单个图表
export function get_chart_by_id(projectId, dashBoardId, chartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart/${chartId}`,

    method: "get",
  });
}
// 删除图表
export function delete_chart_by_id(projectId, dashBoardId, chartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/dashboard/${dashBoardId}/chart/${chartId}`,

    method: "delete",
  });
}
// 获取节点概览
export function get_node_overview(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/condition`,

    method: "post",
    data,
  });
}

// 获取指定类型字段
export function get_node_fields(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/getDashboardFields/fieldTypes`,

    method: "post",
    data,
  });
}

// vmodel付费
export function get_pay_vmodel(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/vmodel/payFuture`,

    method: "get",
  });
}

// vmodel付费
export function get_expired_vmodel() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/tenant/vmodel/payFuture/expired`,

    method: "get",
  });
}
