<template>
  <div
    class="chart-box-wrap"
    :style="
      currentChartData.type === 'STATUS_TIMES' ||
      currentChartData.type === 'STATUS_DURATION'
        ? 'padding-bottom:50px'
        : ''
    "
  >
    <div
      v-if="currentChartData.type === 'STATUS_TIMES'"
      class="chart_state_time"
    >
      <h3 style="margin-right: 25px">{{ $t("chart.message.ms4") }}</h3>
      <div style="margin-right: 25px">
        <span>{{ $t("chart.message.ms5") }}</span>
        <el-date-picker
          v-model="status_start_time"
          type="date"
          :placeholder="$t('placeholder.dropdown')"
          size="mini"
          style="max-width: 140px; margin-left: 5px"
          value-format="yyyy-MM-dd"
          @change="time_change"
        >
        </el-date-picker>
      </div>
      <div style="margin-right: 25px">
        <span>{{ $t("chart.message.ms7") }}</span>
        <el-date-picker
          v-model="status_end_time"
          type="date"
          :placeholder="$t('placeholder.dropdown')"
          size="mini"
          style="max-width: 140px; margin-left: 5px"
          value-format="yyyy-MM-dd"
          @change="time_change"
        >
        </el-date-picker>
      </div>
    </div>

    <div
      v-if="currentChartData.type === 'STATUS_DURATION'"
      class="chart_state_time"
    >
      <h3 style="margin-right: 25px">{{ $t("chart.message.ms4") }}</h3>
      <div style="margin-right: 25px">
        <span>{{ $t("chart.message.ms5") }}</span>
        <el-date-picker
          v-model="status_duration_start_time"
          type="date"
          :placeholder="$t('placeholder.dropdown')"
          size="mini"
          style="max-width: 140px; margin-left: 5px"
          value-format="yyyy-MM-dd"
          @change="duration_change"
        >
        </el-date-picker>
      </div>
      <div style="margin-right: 25px">
        <span>{{ $t("chart.message.ms7") }}</span>
        <el-date-picker
          v-model="status_duration_end_time"
          type="date"
          :placeholder="$t('placeholder.dropdown')"
          size="mini"
          style="max-width: 140px; margin-left: 5px"
          value-format="yyyy-MM-dd"
          @change="duration_change"
        >
        </el-date-picker>
      </div>
    </div>

    <div
      :id="'data_chart' + currentChartData.chartId"
      :ref="'data_chart' + currentChartData.chartId"
      class="chart_box"
    ></div>
  </div>
</template>

<script>
import {
  get_condition_by_id,
  get_file_condition_by_id,
} from "@/network/nodePage/index.js";
import {
  getStatusTimes_file,
  getStatusDuration_file,
  getStatusTimes,
  getStatusDuration,
} from "@/network/charts/index.js";
import { mapGetters } from "vuex";

export default {
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters(["userAccountId"]),
  },
  data() {
    return {
      status_start_time: "",
      status_end_time: "",
      status_duration_start_time: "",
      status_duration_end_time: "",
    };
  },
  watch: {
    currentChartData: {
      handler(newValue, oldValue) {
        this.getFirstStatusData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFirstStatusData();
  },
  methods: {
    // 初始化的时候请求一次默认时间区间数据 针对平均跳转次数和时长
    getFirstStatusData() {
      if (this.currentChartData.type === "STATUS_TIMES") {
        this.get_status_times(true);
      } else if (this.currentChartData.type === "STATUS_DURATION") {
        this.get_status_duration(true);
      }
    },
    // 状态跳转次数 时间改变
    time_change() {
      if (
        this.status_start_time &&
        this.status_end_time &&
        this.status_start_time > this.status_end_time
      ) {
        this.$message({
          message: this.$t("baseChart.selectCorrectTimeRange"),
          type: "warning",
        });
        return;
      }
      this.get_status_times();
    },
    // 状态平均所处时长 时间改变
    duration_change(item) {
      if (
        this.status_duration_start_time &&
        this.status_duration_end_time &&
        this.status_duration_start_time > this.status_duration_end_time
      ) {
        this.$message({
          message: this.$t("baseChart.selectCorrectTimeRange"),
          type: "warning",
        });
        return;
      }
      this.get_status_duration();
    },
    // 获取状态次数数据
    get_status_times(flag) {
      if (this.currentChartData.statisticObject == "FILE") {
        get_file_condition_by_id(this.get_pid()).then((res) => {
          let queryConditions = [];
          res.forEach((ele) => {
            if (ele.createdBy == this.userAccountId) {
              ele.fileQueryConditionDtoList.forEach((item) => {
                if (
                  item.queryConditionId ==
                  this.currentChartData.queryConditionId
                ) {
                  queryConditions = item.conditions;
                }
              });
            }
          });
          const filter = {
            desc: false,
            fuzzyKey: "",
            queryConditions: queryConditions,
            sortBy: "",
          };
          let times = {
            afterTime: new Date("2022-01-01"),
            beforeTime: new Date("3000-04-27"),
            filter: filter,
          };
          // 第一次初始化flag
          if ((this.status_start_time && this.status_end_time) || flag) {
            flag
              ? ""
              : (times = {
                  afterTime: new Date(this.status_start_time),
                  beforeTime: new Date(this.status_end_time),
                  filter: filter,
                });
            getStatusTimes_file(this.get_pid(), times, filter).then((res) => {
              const obj = Object.assign({}, this.currentChartData, {
                entryList: res.StatusCountList,
              });
              this.$emit("initBasechart", obj);
            });
          }
        });
      } else {
        get_condition_by_id(
          this.get_pid(),
          this.currentChartData.queryConditionId
        ).then((res) => {
          const queryConditions = res.conditions;
          const mql = res.mqlCondition ? res.mqlCondition.mql : "";
          const filter = {
            desc: false,
            fuzzyKey: "",
            queryConditions: queryConditions,
            sortBy: "",
            mql,
          };
          let times = {
            afterTime: new Date("2022-01-01"),
            beforeTime: new Date("3000-04-27"),
            filter: filter,
          };
          // 第一次初始化flag
          if ((this.status_start_time && this.status_end_time) || flag) {
            flag
              ? ""
              : (times = {
                  afterTime: new Date(this.status_start_time),
                  beforeTime: new Date(this.status_end_time),
                  filter: filter,
                });
            getStatusTimes(this.get_pid(), times, filter).then((res) => {
              const obj = Object.assign({}, this.currentChartData, {
                entryList: res.StatusCountList,
              });
              this.$emit("initBasechart", obj);
            });
          }
        });
      }
    },
    // 获取状态时长数据
    get_status_duration(flag) {
      if (this.currentChartData.statisticObject == "FILE") {
        get_file_condition_by_id(this.get_pid()).then((res) => {
          let queryConditions = [];
          res.forEach((ele) => {
            if (ele.createdBy == this.userAccountId) {
              ele.fileQueryConditionDtoList.forEach((item) => {
                if (
                  item.queryConditionId ==
                  this.currentChartData.queryConditionId
                ) {
                  queryConditions = item.conditions;
                }
              });
            }
          });

          const filter = {
            desc: false,
            fuzzyKey: "",
            queryConditions: queryConditions,
            sortBy: "",
          };
          let times = {
            afterTime: new Date("2022-01-01"),
            beforeTime: new Date("3000-04-27"),
            filter: filter,
          };
          if (
            (this.status_duration_start_time &&
              this.status_duration_end_time) ||
            flag
          ) {
            flag
              ? ""
              : (times = {
                  afterTime: new Date(this.status_duration_start_time),
                  beforeTime: new Date(this.status_duration_end_time),
                  filter: filter,
                });
            getStatusDuration_file(this.get_pid(), times, filter).then(
              (res) => {
                const obj = Object.assign({}, this.currentChartData, {
                  entryList: res.StatusDurationList,
                });
                this.$emit("initBasechart", obj);
              }
            );
          }
        });
      } else {
        get_condition_by_id(
          this.get_pid(),
          this.currentChartData.queryConditionId
        ).then((res) => {
          const queryConditions = res.conditions;
          const mql = res.mqlCondition ? res.mqlCondition.mql : "";
          const filter = {
            desc: false,
            fuzzyKey: "",
            queryConditions: queryConditions,
            sortBy: "",
            mql,
          };
          let times = {
            afterTime: new Date("2022-01-01"),
            beforeTime: new Date("3000-04-27"),
            filter: filter,
          };
          if (
            (this.status_duration_start_time &&
              this.status_duration_end_time) ||
            flag
          ) {
            flag
              ? ""
              : (times = {
                  afterTime: new Date(this.status_duration_start_time),
                  beforeTime: new Date(this.status_duration_end_time),
                  filter: filter,
                });
            getStatusDuration(this.get_pid(), times, filter).then((res) => {
              const obj = Object.assign({}, this.currentChartData, {
                entryList: res.StatusDurationList,
              });
              this.$emit("initBasechart", obj);
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-box-wrap {
  right: 35px;
  width: 100%;
  height: 400px;
  position: relative;
  .chart_state_time {
    display: flex;
    align-items: center;
    // width: 20%;
    // position: absolute;
    // right: 0;
    // top: 0;
    margin-left: 55px;
    z-index: 100;
    margin-right: 10px;
  }
}
.chart_box {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
>
