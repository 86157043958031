<template>
  <el-cascader
    ref="cascader"
    v-model="permisson"
    collapse-tags
    filterable
    clearable
    class="box-card-top-row-select-main"
    :options="options"
    :show-all-levels="false"
    :props="{ multiple: true }"
    size="mini"
    @change="handleChange"
  >
  </el-cascader>
</template>

<script>
export default {
  name: "Permisson",
  props: {
    result: { type: Array, default: () => [] }, // 父组件传来的result数组
    change: { type: Function, default: () => {} }, // 更改父组件数据的函数
    isEditing: { type: Boolean, default: () => false }, //取消编辑时调用接口
    option_children: { type: Array, default: () => {} }, // cascader二级选项
    is_view: { type: Boolean, default: () => false }, // 是否是查看权限
    groupall: { type: Array, default: () => [] },
  },
  data() {
    return {
      permisson: [],
      isDisabled: "none",
      options: [
        {
          value: "PUBLIC",
          label: this.$t("chart.basicInfo.authorityList.all"),
          disabled: false,
        },
        {
          value: "PRIVATE",
          label: this.$t("chart.basicInfo.authorityList.private"),
          disabled: false,
        },
        {
          value: "RESTRICTED",
          label: this.$t("chart.basicInfo.authorityList.part"),
          disabled: false,
          children: [
            {
              value: "RESTRICTED_USER",
              label: this.$t("knowledge.user"),
              children: [],
            },
            {
              value: "RESTRICTED_USERGROUP",
              label: this.$t("knowledge.userGroup"),
              children: [],
            },
          ],
        },
      ],
    };
  },
  watch: {
    isDisabled: function () {
      switch (this.isDisabled) {
        case "none":
          this.options[0].disabled = false;
          this.options[1].disabled = false;
          this.options[2].disabled = false;
          break;
        case "public":
          this.options[0].disabled = false;
          this.options[1].disabled = true;
          this.options[2].disabled = true;
          break;
        case "PRIVATE":
          this.options[0].disabled = true;
          this.options[1].disabled = false;
          this.options[2].disabled = true;
          break;
        case "RESTRICTED":
          this.options[0].disabled = true;
          this.options[1].disabled = true;
          this.options[2].disabled = false;
          break;
      }
    },
    isEditing: function (flag) {
      
    },
    permisson: function (newValue, oldValue) {
      // 取消编辑时调用接口
      if (!newValue.length && !this.is_view) {
        this.$message({
          type: "error",
          message: this.$t("chart.message.warn2"),
        });
      }
    },
    result: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.isDisabled = newValue[0][0];
          this.permisson = [...newValue];
        }
      },
      deep: true,
      immediate: true,
    },
    option_children: {
      handler(newValue) {
        if (newValue.length > 0) {
          this.options[2].children[0].children = [...newValue];
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.groupall.map((item, index) => {
      this.options[2].children[1].children.push({
        label: item.groupName,
        value: item.groupId,
      });
    });
  },
  methods: {
    // 处理级联选择器change
    handleChange(value) {
      if (this.is_view) {
        this.$emit("getreadList", value);
        this.change(value);
      } else {
        this.$emit("geteditList", value);
        this.change(value);
      }
      if (value.length === 0) {
        this.isDisabled = "none";
        return;
      }
      if (value[0][0] === "PUBLIC") {
        this.isDisabled = "public";
      } else if (value[0][0] === "PRIVATE") {
        this.isDisabled = "PRIVATE";
      } else {
        this.isDisabled = "RESTRICTED";
      }
    },
  },
};
</script>

<style></style>
