<template>
  <div class="two-wrap">
    <div style="display: flex; align-items: center">
      <el-tooltip
        :disabled="currentChartData.name && currentChartData.name.length < 24"
        effect="dark"
        placement="top-start"
      >
        <div slot="content">
          <span>{{ currentChartData.name }}</span>
        </div>
        <h1 class="chart_title">
          {{ currentChartData.name }}
        </h1>
      </el-tooltip>
      <div class="top-button-wrap">
        <el-button
          v-show="isEditing"
          type="primary"
          class="setting_button"
          style="margin-left: 10px"
          size="mini"
          @click="edit_chart"
        >
          {{ $t("board.settingPage.title") }}</el-button
        >
        <div v-show="isEditing" class="remove_button" @click="remove_chart">
          <i
            class="el-icon-circle-close"
            style="font-size: 30px; margin-left: 10px"
          ></i>
        </div>
        <i
          v-show="!isEditing"
          v-limits-of-authority="'FILE_DOWNLOAD'"
          class="iconfont icon-download"
          style="font-size: 16px; cursor: pointer"
          @click="exportFn"
        ></i>
      </div>
    </div>
    <div class="table-wrap">
      <div class="table-content-wrap">
        <el-table
          v-loading="loading"
          :data="tableData"
          :span-method="arraySpanMethod"
          border
          style="width: 100%"
          height="400px"
        >
          <el-table-column
            align="left"
            prop="hnodeKey"
            min-width="200"
            show-overflow-tooltip
          >
            <template slot="header" slot-scope="scope">
              <span class="sortable-column-header" @click="sortData('key')"
                >{{ $t("chart.NodesQueried") }}
                <div class="caret-wrapper">
                  <i
                    :class="{
                      'el-icon-caret-top': true,
                      'el-icon-caret-colored': sortBy == 'key' && desc == false,
                    }"
                  ></i>
                  <i
                    :class="{
                      'el-icon-caret-bottom': true,
                      'el-icon-caret-colored': sortBy == 'key' && desc == true,
                    }"
                  ></i>
                </div>
              </span>
            </template>
            <template slot-scope="scope">
              <div
                style="width: 100%; overflow: hidden; text-overflow: ellipsis"
              >
                <span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="get_name(scope.row)"
                  >
                    <i
                      v-if="get_icon(scope.row) !== '-1'"
                      class="iconfont"
                      :class="'icon-a-' + get_icon(scope.row) + ''"
                      style="
                        font-size: 20px;
                        color: rgb(48, 100, 143);
                        vertical-align: middle;
                        margin-right: 6px;
                      "
                    >
                    </i>
                    <span
                      v-else
                      style="
                        font-weight: 700;
                        color: rgb(38, 50, 129);
                        font-size: 16px;
                        margin-right: 6px;
                      "
                      >{{ getFileTypeName(scope.row) }}</span
                    >
                  </el-tooltip>
                </span>
                <span>
                  <a
                    style="color: rgb(255, 195, 0); font-weight: 700"
                    class="hover-class"
                    :href="'/' + get_pid() + '/nodes/key/' + scope.row.hnodeKey"
                  >
                    {{ scope.row.hnodeKey }}</a
                  ></span
                >
                <i
                  style="
                    font-size: 12px;
                    margin-right: 16px;
                    margin-left: 6px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  class="iconfont icon-node_link"
                  @click="nodeLinkH(scope.row)"
                >
                </i>
                <span>
                  <a
                    style="color: rgb(99, 99, 99); font-weight: 700"
                    class="hover-class"
                    :href="'/' + get_pid() + '/nodes/key/' + scope.row.hnodeKey"
                  >
                    {{ scope.row.htopic }}</a
                  ></span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in fileTypeIdList"
            :key="index"
            :prop="item + 'nodeKey'"
            align="left"
            min-width="200"
            show-overflow-tooltip
          >
            <template slot="header" slot-scope="scope">{{
              $t("chart.addDiagramForm.typeList.l") +
              (index + 1) +
              $t("chart.addDiagramForm.typeList.c") +
              ":" +
              fileType[item]
                ? fileType[item].name
                : ""
            }}</template>
            <template slot-scope="scope">
              <div
                v-if="
                  scope.row[item + 'nodeKey'] &&
                  scope.row[item + 'nodeKey'].length
                "
                style="width: 100%; overflow: hidden; text-overflow: ellipsis"
              >
                <span>
                  <a
                    style="color: rgb(255, 195, 0); font-weight: 700"
                    class="hover-class"
                    :href="
                      '/' +
                      get_pid() +
                      '/nodes/key/' +
                      scope.row[item + 'nodeKey']
                    "
                  >
                    {{ scope.row[item + "nodeKey"] }}</a
                  >
                </span>
                <i
                  v-if="scope.row[item + 'nodeKey']"
                  style="
                    font-size: 12px;
                    margin-right: 16px;
                    margin-left: 6px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  class="iconfont icon-node_link"
                  @click="nodeLink(scope.row, item)"
                ></i>
                <span>
                  <a
                    style="color: rgb(99, 99, 99); font-weight: 700"
                    class="hover-class"
                    :href="
                      '/' +
                      get_pid() +
                      '/nodes/key/' +
                      scope.row[item + 'nodeKey']
                    "
                    >{{ scope.row[item + "topic"] }}</a
                  >
                </span>
              </div></template
            >
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页器 -->
    <div class="my-page">
      <el-pagination
        :current-page="pageData.pageNumber"
        background
        small
        :page-size="20"
        layout="total, prev, pager, next"
        :total="pageData.total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <addChartDialog
      ref="addchart"
      :dialog-show="dialogShow"
      :field-list="fieldList"
      :is-editing-chart="true"
      :current-select="$route.params.cur_dashboard"
      :chart-sources="chartSources"
      :chart-form="chartForm"
      @changeDialogShow="changeDialogShow"
      @partChangeChart="partChangeChart"
    ></addChartDialog>
  </div>
</template>

<script>
import addChartDialog from "../addChartDialog.vue";
import { mapGetters } from "vuex";
import {
  add_t_c,
  delete_chart_by_id,
  exportTwoD,
} from "@/network/dataChart/index.js";
import { getFile } from "@/network/home/index.js";
import { get_file } from "@/network/baseline/index.js";
import { get_node_use_node_key } from "@/network/node/index.js";
export default {
  components: {
    addChartDialog,
  },
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    chartSources: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableData: [],
      pageData: {
        pageNumber: 1,
        total: 0,
      },
      fileTypeIdList: [],
      dialogShow: false,
      chartForm: {},
      loading: false,
      mergeObj: {},
      mergeList: [],
      sortBy: "key",
      desc: true,
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
  },
  watch: {
    currentChartData() {
      this.fileTypeIdList = [];
      this.currentChartData.frontExtraData.forEach((item) => {
        if (item.fileType) {
          this.fileTypeIdList.push(item.fileType);
        }
      });
      this.getTableData();
    },
  },
  mounted() {
    this.showTable = false;
    this.fileTypeIdList = [];
    this.currentChartData.frontExtraData.forEach((item) => {
      if (item.fileType) {
        this.fileTypeIdList.push(item.fileType);
      }
    });
    this.getTableData();
  },
  methods: {
    sortData() {
      this.desc = !this.desc;
      this.getTableData();
    },
    get_name(o) {
      return this.fileType[o.hfileTypeId].name;
    },
    get_icon(o) {
      return this.fileType[o.hfileTypeId].icon;
    },
    getFileTypeName(o) {
      return this.fileType[o.hfileTypeId].name.slice(0, 1);
    },
    exportFn() {
      const params = {
        projectId: this.get_pid(),
        pageData: {
          pageNumber: this.pageData.pageNumber - 1,
        },
        data: {
          queryConditionId: this.currentChartData.queryConditionId,
          fileTypeIdList: this.fileTypeIdList,
          sortBy: this.sortBy,
          desc: this.desc,
        },
      };
      exportTwoD(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("homeTopBar.asyncTask.message"),
        });
      });
    },
    nodeLinkH(row) {
      this.jumpTofile(row, row.hfileUuid, row.hnodeKey);
    },
    nodeLink(row, fileType) {
      this.jumpTofile(
        row,
        row[fileType + "fileUuid"],
        row[fileType + "nodeKey"]
      );
    },
    jumpTofile(row, fileid, nodekey) {
      this.loading = true;
      get_file(this.get_pid(), fileid).then((result) => {
        getFile(this.get_pid(), result.key).then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", result.id);
          this.$store.commit("SET_FILE_KEY", result.key);
          this.$store.commit("SET_MINDMAP_NAME", result.name);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", result.fileTypeId);
          //跳转到思维导图
          get_node_use_node_key(this.get_pid(), nodekey).then((nodeInfo) => {
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${result.key}`,
              query: {
                node_id: nodeInfo.id, // 节点id
              },
            });
            window.open(href, "_blank");
            this.loading = false;
          });
        });
      });
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // 判断列的属性
      if (this.mergeList.indexOf(column.property) !== -1) {
        // 判断其值是不是为0
        if (this.mergeObj[column.property][rowIndex]) {
          return [this.mergeObj[column.property][rowIndex], 1];
        } else {
          // 如果为0则为需要合并的行
          return [0, 0];
        }
      }
    },
    changeDialogShow(flag) {
      this.dialogShow = flag;
    },
    partChangeChart() {
      this.$emit(
        "partChangeChart",
        this.$route.params.cur_dashboard,
        "update",
        true
      );
    },
    getTableData() {
      this.loading = true;
      this.tableData = [];
      const params = {
        projectId: this.get_pid(),
        pageData: {
          pageNumber: this.pageData.pageNumber - 1,
        },
        data: {
          queryConditionId: this.currentChartData.queryConditionId,
          fileTypeIdList: this.fileTypeIdList,
          sortBy: this.sortBy,
          desc: this.desc,
        },
      };
      add_t_c(params).then((res) => {
        const data = this.fixData(this.makeTableData(res.content));
        this.mergeList = this.fileTypeIdList.map((item) => {
          return item + "nodeKey";
        });
        this.mergeList.unshift("hnodeKey");
        this.makeMerge(data);
        this.tableData = data;
        this.pageData.total = res.totalElements;
        this.loading = false;
      });
    },
    makeMerge(data) {
      this.mergeList.forEach((key, index1) => {
        let count = 0; // 用来记录需要合并行的起始位置
        this.mergeObj[key] = []; // 记录每一列的合并信息
        data.forEach((item, index) => {
          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            this.mergeObj[key].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[key] === data[index - 1][key]) {
              this.mergeObj[key][count] += 1;
              this.mergeObj[key].push(0);
            } else {
              // 如果当前行和上一行其值不相等
              count = index; // 记录当前位置
              this.mergeObj[key].push(1); // 重新push 一个 1
            }
          }
        });
      });
    },
    fixData(res) {
      res.forEach((data) => {
        this.fileTypeIdList.forEach((type) => {
          if (!data[type + "nodeKey"]) {
            data[type + "nodeKey"] = [];
          }
        });
      });
      return res;
    },
    makeTableData(res) {
      function recursion(itemDtoList, AllObj, arr) {
        itemDtoList.forEach((item) => {
          if (item.itemDtoList && item.itemDtoList.length) {
            Object.assign(AllObj, {
              [item.fileTypeId + "fileTypeId"]: item.fileTypeId,
              [item.fileTypeId + "nodeKey"]: item.nodeKey,
              [item.fileTypeId + "topic"]: item.topic,
              [item.fileTypeId + "fileUuid"]: item.fileUuid,
            });
            recursion(item.itemDtoList, AllObj, arr);
          } else {
            const obj = Object.assign({}, AllObj, {
              [item.fileTypeId + "fileTypeId"]: item.fileTypeId,
              [item.fileTypeId + "nodeKey"]: item.nodeKey,
              [item.fileTypeId + "topic"]: item.topic,
              [item.fileTypeId + "fileUuid"]: item.fileUuid,
            });
            arr.push(obj);
          }
        });
      }
      const arr = [];
      res.forEach((item) => {
        const AllObj = {};
        Object.assign(AllObj, {
          hfileTypeId: item.fileTypeId,
          hnodeKey: item.nodeKey,
          htopic: item.topic,
          hfileUuid: item.fileUuid,
        });
        if (item.itemDtoList && item.itemDtoList.length) {
          recursion(item.itemDtoList, AllObj, arr);
        } else {
          arr.push(Object.assign({}, AllObj));
        }
      });
      return arr;
    },
    handleCurrentChange(page) {
      this.pageData.pageNumber = page;
      this.getTableData();
    },
    // 编辑
    edit_chart() {
      this.chartForm = {
        name: this.currentChartData.name,
        type: this.currentChartData.type,
        chartId: this.currentChartData.chartId,
        statisticObject: "NODE",
        queryConditionId: this.currentChartData.queryConditionId
          ? this.currentChartData.queryConditionId
          : "all",
        fieldId: this.currentChartData.fieldId,
        fieldId02: this.currentChartData.fieldId02,
        frontExtraData: this.currentChartData.frontExtraData,
      };
      this.dialogShow = true;
      this.$refs["addchart"].makeLevel(this.currentChartData.frontExtraData);
    },
    // 删除
    remove_chart() {
      delete_chart_by_id(
        this.get_pid(),
        this.currentChartData.dashboardId,
        this.currentChartData.chartId
      )
        .then((result) => {
          this.$message({
            type: "success",
            message: this.$t("chart.message.deleteSuccess"),
          });
          this.$emit(
            "partChangeChart",
            this.$route.params.cur_dashboard,
            "delete"
          );
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: this.$t("chart.message.deleteError"),
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.two-wrap {
  position: relative;
}
.top-button-wrap {
  position: absolute;
  right: 50px;
  top: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  justify-content: flex-end;
  .remove_button {
    cursor: pointer;
  }
}
.table-wrap {
  width: 100%;
  margin: 0 auto;
  overflow-x: scroll;
}
.table-content-wrap {
  overflow-y: scroll;
  min-height: 400px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  //   overflow-x: hidden;

  .table-line-wrap {
    display: flex;
    justify-content: space-around;
    & > div {
      min-width: 25%;
    }
  }
}

.sortable-column-header {
  display: flex;
  align-items: center;
  // cursor: pointer;
}

.el-icon-caret-top {
  transform: translate(0, 6px);
}

.el-icon-caret-bottom {
  transform: translate(0, 0px);
}

.el-icon-caret-colored {
  color: #068bff;
}
</style>
