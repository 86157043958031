import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
//燃尽图
export function getSprintBurnChart(projectId, sprintId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/sprints/${sprintId}/SprintBurnChart`,
    method: 'get',
  })
}

export function getStatusTimes(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/status/count/filter`,
    method: 'post',
    data: data,
  })
}

export function getStatusDuration(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/status/duration/filter`,
    method: 'post',
    data: data,
  })
}

export function getStatusTimes_file(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/files/status/count/filter`,
    method: 'post',
    data: data,
  })
}
export function getStatusDuration_file(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/files/status/duration/filter`,
    method: 'post',
    data: data,
  })
}

// 查询覆盖率
export function getVmodelNode(projectId, chartId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/vmodelNodeCoverage?chartId=${chartId}`,
    method: 'post',
  })
}
