<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <chartTop
      ref="charTop"
      :chart-options="chartOptions"
      :field-map="fieldMap"
      :field-list="fieldList"
      :chart-sources="chartSources"
      @updateChartDtos="updateChartDtos"
      @partChangeChart="partChangeChart"
      @updateDashboardId="updateDashboardId"
      @updateIsEditing="updateIsEditing"
      v-on="$listeners"
    ></chartTop>
    <div style="flex: 1; overflow-y: scroll">
      <!-- 二维覆盖度报表 -->
      <div
        v-for="(item, index) in chartDtos"
        v-show="item.type === 'TWO_DIMENSION_COVERAGE'"
        :key="index"
        class="two-class"
      >
        <twoDimensionCoverage
          v-if="item.type === 'TWO_DIMENSION_COVERAGE' && showTwo"
          :is-editing="isEditing"
          :current-chart-data="item"
          :chart-sources="chartSources"
          :field-list="fieldList"
          @partChangeChart="partChangeChart"
        >
        </twoDimensionCoverage>
      </div>
      <div class="charts-wrap">
        <draggable
          id="chart_list_even"
          v-model="chartDtos"
          style="break-inside: avoid"
          chosen-class="chosen"
          group="chart"
          animation="300"
          :scroll="true"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group
            style="break-inside: avoid; min-height: 120px; display: block"
          >
            <chart
              v-for="item in chartDtos"
              v-show="item.type !== 'TWO_DIMENSION_COVERAGE'"
              :key="item.chartId"
              :field-map="fieldMap"
              :field-list="fieldList"
              :chart-sources="chartSources"
              :is-editing="isEditing"
              :dashboard-id="dashboardId"
              :chart-data="item"
              @partChangeChart="partChangeChart"
            ></chart>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import chartTop from "./chartTop.vue";
import chart from "./chart.vue";
import draggable from "vuedraggable";
import { get_dashboard_detail } from "@/network/dataChart/index.js";
import twoDimensionCoverage from "./charts/twoDimensionCoverage.vue";
export default {
  components: {
    chartTop,
    chart,
    draggable,
    twoDimensionCoverage,
  },
  props: {
    chartOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    chartSources: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldMap: {
      type: Map,
      default() {
        return new Map();
      },
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chartDtos: [],
      dashboardId: "",
      isEditing: false,
      showTwo: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeChart(charId) {
      this.$refs.charTop.changeChart(charId);
    },
    // 更新每个board的所有图表list(切换board时候用这个)
    updateChartDtos(chartDtos) {
      if (localStorage.getItem(this.dashboardId)) {
        const sortList = JSON.parse(localStorage.getItem(this.dashboardId));
        const sortedArr = chartDtos.sort((a, b) => {
          const indexA = sortList.indexOf(a.chartId);
          const indexB = sortList.indexOf(b.chartId);

          if (indexA === -1 && indexB === -1) {
            return 0;
          } else if (indexA === -1) {
            return 1;
          } else if (indexB === -1) {
            return -1;
          } else {
            return indexA - indexB;
          }
        });
        this.chartDtos = sortedArr;
      } else {
        this.chartDtos = chartDtos;
      }
    },
    // 部分更新图表(切换board时候不用这个, 增删改图表的时候用这个)
    partChangeChart(id, type, flag) {
      this.showTwo = false;
      get_dashboard_detail(this.get_pid(), id).then((res) => {
        this.chartDetail = { ...res };
        //更新方法
        function compareChartObjects(arr1, arr2) {
          arr1.sort((a, b) => a.chartId - b.chartId);
          arr2.sort((a, b) => a.chartId - b.chartId);
          for (let i = 0; i < arr1.length; i++) {
            for (let j = 0; j < arr2.length; j++) {
              let obj1 = arr1[i];
              let obj2 = arr2[j];
              if (obj1.chartId === obj2.chartId) {
                // 如果两个对象的 chartId 相同，则比较它们的属性值
                for (let prop in obj1) {
                  if (
                    prop !== "chartId" &&
                    JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop])
                  ) {
                    // 如果属性值不同，则是修改项
                    Object.assign(obj1, obj2); // 用 obj2 覆盖 obj1，并更新 obj1 的属性值
                    break;
                  }
                }
              }
            }
          }
        }
        // 新增方法
        function findExtraItems(a, b) {
          let extraItems = [];
          for (let i = 0; i < a.length; i++) {
            let found = false;
            for (let j = 0; j < b.length; j++) {
              if (a[i].chartId === b[j].chartId) {
                found = true;
                break;
              }
            }
            if (!found) {
              extraItems.push(a[i]);
            }
          }
          return extraItems;
        }
        // 删除方法
        function removeMissingItems(a, b) {
          for (let i = 0; i < b.length; i++) {
            let found = false;
            for (let j = 0; j < a.length; j++) {
              if (b[i].chartId === a[j].chartId) {
                found = true;
                break;
              }
            }
            if (!found) {
              b.splice(i, 1);
              i--;
            }
          }
        }
        if (type === "update") {
          compareChartObjects(this.chartDtos, this.chartDetail.chartDtos);
        } else if (type === "add") {
          const arr = findExtraItems(
            this.chartDetail.chartDtos,
            this.chartDtos
          );
          this.chartDtos.unshift(...arr);
        } else if (type === "delete") {
          if (this.chartDtos.length == 1) {
            this.chartDetail.chartDtos = this.chartDtos[0];
          }
          removeMissingItems(this.chartDetail.chartDtos, this.chartDtos);
        }
        this.showTwo = true;
      });
    },
    // 更新目前选中的DashboardId
    updateDashboardId(id) {
      this.dashboardId = id;
    },
    // 更新是否编辑状态
    updateIsEditing(isEditing) {
      this.isEditing = isEditing;
    },
    //开始拖拽事件
    onStart(event) {},
    //拖拽结束事件
    onEnd(event) {
      localStorage.setItem(
        `${this.dashboardId}`,
        JSON.stringify(
          this.chartDtos.map((item) => {
            return item.chartId;
          })
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.charts-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  #chart_list_even {
    width: 100%;
    & > span {
      width: 100%;
      column-count: 2;
    }
  }
}
.two-class {
  break-inside: avoid;
  text-align: center;
  border-radius: 8px;
  padding: 12px 0 10px 20px;
  background: white;
  margin-bottom: 20px;
  position: relative;
  height: fit-content;
  background: white;
}
</style>
