<template>
  <el-dialog
    :visible.sync="selfDialogShow"
    width="25%"
    top="25vh"
    :title="$t('chart.addChartForm.title')"
    :close-on-click-modal="false"
    :show-close="true"
    custom-class="create_dashboard"
  >
    <el-form
      ref="addDatachartForm"
      label-width="150px"
      :model="addChartForm"
      label-position="left"
      :rules="rules"
    >
      <el-form-item prop="name" :label="$t('chart.addChartForm.name')">
        <el-input v-model="addChartForm.name"> </el-input>
      </el-form-item>
      <el-form-item :label="$t('chart.addChartForm.desc')">
        <el-input v-model="addChartForm.description"> </el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="info" @click="hideDialog">{{
        $t("btn.cancelBtn")
      }}</el-button>
      <el-button type="primary" class="button-confirm" @click="createChart">{{
        $t("btn.newBtn")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add_dashboard } from "@/network/dataChart/index.js";
export default {
  components: {},
  props: {
    dialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addChartForm: {
        name: "",
        description: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("dataChart.PleaseEnterReportName"),
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    selfDialogShow: {
      get() {
        return this.dialogShow;
      },
      set(val) {
        this.$emit("changeDialogShow", val);
        return val;
      },
    },
  },
  methods: {
    createChart() {
      this.$refs.addDatachartForm.validate((valid) => {
        if (valid) {
          add_dashboard(this.get_pid(), this.addChartForm)
            .then((charId) => {
              this.$emit("get_dashboard_select", () => {
                this.$emit("addChartSucess", charId);
              });
            })
            .then(() => {
              this.$emit("changeDialogShow", false);
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          return false;
        }
      });
    },
    hideDialog() {
      this.$emit("changeDialogShow", false);
    },
  },
};
</script>

<style></style>
