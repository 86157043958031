<template>
  <div>
    <!-- 筛选结果 -->
    <div class="node_list">
      <!-- 节点列表 -->
      <el-table
        :data="nodeData"
        class="clickable-rows"
        :cell-style="changeCellStyle"
        :header-cell-style="{ border: 'none' }"
      >
        <el-table-column
          :label="nodeDataSystem[0].label"
          fixed="left"
          :prop="nodeDataSystem[0].prop"
          :width="nodeDataSystem[0].width"
          :min-width="nodeDataSystem[0].minWidth"
          show-overflow-tooltip
        >
          <template slot="header">
            <span class="sortable-column-header" @click="filekey_sort"
              >Key
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored': keySort == false,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored': keySort == true,
                  }"
                ></i>
              </div>
            </span>
          </template>
          <template slot-scope="scope">
            <div style="display: inline-block">
              <span
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="cursor: pointer"
                @click="rowClicked(scope.row)"
                >{{ scope.row.key }}</span
              >
            </div>
            <div style="display: inline-block">
              <div
                v-limits-of-authority="'FILE_READ'"
                style="display: inline-block"
              >
                <i
                  style="
                    font-size: 12px;
                    margin-left: 16px;
                    cursor: pointer;
                    color: rgb(48, 100, 143);
                  "
                  class="iconfont icon-node_link"
                  @click="nodeLink(scope.row)"
                ></i>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="col in nodeDataSystem.slice(1)"
          :key="col.prop"
          :label="col.label"
          :prop="col.prop"
          :width="col.width"
          :min-width="col.minWidth"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="col.prop === 'fileTypeName'">
              {{ scope.row.fileTypeName }}
            </span>
            <div v-else-if="col.prop === 'topic'" style="display: inline-block">
              <span
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="
                  cursor: pointer;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                "
                :style="{ width: col.width - 10 + 'px' }"
                @click="rowClicked(scope.row)"
                >{{ scope.row.topic }}</span
              >
            </div>
            <span v-else-if="col.prop === 'createdAt'">
              {{ new Date(scope.row.createdAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in JSON.parse(currentChartData.frontExtraData)"
          :key="i"
          :label="getColumnInfo(col).label"
          :min-width="getColumnInfo(col).minWidth"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            <div @mouseover="onMouseOver('refName' + scope.$index)">
              <el-tooltip
                :disabled="isShowTooltip"
                :content="getColumnInfo(col).label"
                placement="top"
              >
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  <span :ref="'refName' + scope.$index">{{
                    getColumnInfo(col).label
                  }}</span>
                </div>
              </el-tooltip>
            </div>
          </template>

          <template slot-scope="scope">
            <span v-if="getColumnInfo(col).isCus && col === 'status'">
              <status-tag
                v-model="scope.row.statusName"
                :style_catogry="'plain'"
                :use="false"
                :status_type="status[scope.row.status].statusCategory"
                :size="'small'"
              >
              </status-tag>
            </span>
            <span v-else-if="getColumnInfo(col).isCus && col === 'created_by'">
              {{ scope.row.createdBy }}
            </span>
            <span v-else-if="getColumnInfo(col).isCus && col === 'assignee'">
              {{ scope.row.assignee }}
            </span>
            <span
              v-else-if="getColumnInfo(col).isCus && col === 'nodeRelationList'"
            >
              {{ getnodeRelationName(scope.row.nodeRelationList) }}
            </span>
            <span v-else-if="getColumnInfo(col).isCus && col === 4">
              {{ new Date(scope.row.createdAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
            <span
              v-else-if="
                getColumnInfo(col).isCus &&
                [
                  'baseline_id',
                  'version_id',
                  'test_plan_id',
                  'sprint_id',
                ].indexOf(col) === -1
              "
            >
              {{ get_cus_value(scope.row, col) }}
            </span>
            <span
              v-else-if="
                getColumnInfo(col).isCus &&
                [
                  'baseline_id',
                  'version_id',
                  'test_plan_id',
                  'sprint_id',
                ].indexOf(col) !== -1
              "
            >
              {{ getReleastName(col, scope.row) }}
            </span>
            <span v-else>{{ scope.row[getColumnInfo(col).prop] }}</span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div class="my-page">
        <el-pagination
          :current-page="page.current"
          background
          small
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_condition_by_id,
  get_file_List,
} from "@/network/nodePage/index.js";
import { mqlFilter } from "@/network/node/index.js";
import { get_file } from "@/network/baseline/index";
import { getFile } from "@/network/home/index.js";
import { mapGetters } from "vuex";
import StatusTag from "@/components/statusTag";
export default {
  components: {
    StatusTag,
  },
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      nodeData: [],
      nodeDataSystem: [
        { prop: "key", label: "Key", width: 150, minWidth: 150, fieldId: 2 },
        {
          prop: "fileTypeName",
          label: this.$t("node.table.type"),
          width: 150,
          minWidth: 150,
          fieldId: 1,
        },
        {
          prop: "topic",
          label: this.$t("node.table.name"),
          width: 300,
          minWidth: 170,
          fieldId: 3,
        },
      ],
      page: {
        current: 1,
        total: 0,
      },
      loading: false,
      keySort: true,
      isShowTooltip: false,
      filterFieldList: [...this.fieldList],
    };
  },
  computed: {
    ...mapGetters([
      "testPlan",
      "version",
      "sprint",
      "baseLine",
      "fileType",
      "status",
    ]),
  },
  watch: {
    currentChartData: {
      handler(newValue, oldValue) {
        this.getTableData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      get_condition_by_id(
        this.get_pid(),
        this.currentChartData.queryConditionId
      ).then((r) => {
        let conditions = r.conditions;
        if (r.queryType === "MQL") {
          const params = {
            projectId: this.get_pid(),
            data: {
              content: r.mqlCondition.mql,
              sortBy: "key",
              desc: this.keySort,
            },
            pageSize: 10,
            pageNumber: this.page.current - 1,
          };
          mqlFilter(params).then((res) => {
            this.nodeData = res.content;
            this.nodeData.forEach((item) => {
              item.fileTypeName = this.fileType[item.fileTypeId].name;
              item.createdBy = this.matchUserNickname(item.createdBy, "");
              item.assignee = this.matchUserNickname(item.assignee, "");
              item.statusName = this.status[item.status].name;
            });
            this.$set(this.page, "total", res.totalElements);
            this.loading = false;
          });
        } else {
          get_file_List(
            this.keySort,
            this.page.current - 1,
            10,
            this.get_pid(),
            "key",
            conditions,
            ""
          ).then((res) => {
            this.nodeData = res.content;
            this.nodeData.forEach((item) => {
              item.fileTypeName = this.fileType[item.fileTypeId].name;
              item.createdBy = this.matchUserNickname(item.createdBy, "");
              item.assignee = this.matchUserNickname(item.assignee, "");
              item.statusName = this.status[item.status].name;
            });
            this.$set(this.page, "total", res.totalElements);
            this.loading = false;
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.$set(this.page, "current", Number(val));
      this.getTableData();
    },
    //key排序
    filekey_sort() {
      this.keySort = !this.keySort;
      this.sortBy = "Key";
      this.getTableData();
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
    // 跳转文件事件
    nodeLink(row) {
      get_file(this.get_pid(), row.fileId).then((res) => {
        row.fileKey = res.key;
        getFile(this.get_pid(), row.fileKey)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", row.fileId);
            this.$store.commit("SET_FILE_KEY", row.fileKey);
            this.$store.commit("SET_MINDMAP_NAME", row.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", row.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${row.fileKey}`,
              query: {
                node_id: row.id,
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    // 筛选结果报表 获取节点数据
    getColumnInfo(fieldId) {
      const newItem = {
        prop: "createdAt",
        extraData: null,
        fieldId: 4,
        fieldName: "createdAt",
        fieldType: "DATE",
        isCus: true,
        isCustom: true,
        label: this.$t("node.table.createdTime"),
        minWidth: 180,
        name: this.$t("node.table.createdTime"),
      };
      if (fieldId == 4) {
        return newItem;
      }
      const systemIds = [1, 2, 3, 4];
      const col = this.fieldList.filter((col) => col.fieldId === fieldId)[0];
      if (!systemIds.includes(col.fieldId)) {
        col.isCus = true;
        col.minWidth = 150;
        col.label = col.name;
      }
      return col;
    },
    // 表头判断是否长度超出
    onMouseOver(refName) {
      let parentWidth = this.$refs[refName][0].parentNode.offsetWidth;
      let contentWidth = this.$refs[refName][0].offsetWidth;
      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
    // 关联节点key
    getnodeRelationName(list) {
      const arr = [];
      list &&
        list.forEach((item) => {
          arr.push(item.targetNodeKey);
        });
      return arr.join(", ");
    },
    // 获取表格中所有release的name
    getReleastName(fieldId, row) {
      const map = {
        baseline_id: "baselineIdList",
        version_id: "versionIdList",
        testPlan_id: "testPlanIdList",
        sprint_id: "sprintId",
      };
      const store_map = {
        baseline_id: "baseLine",
        version_id: "version",
        testPlan_id: "testPlan",
        sprint_id: "sprint",
      };
      if (typeof row[map[fieldId]] === "string") {
        // sprint
        if (row[map[fieldId]] && this[store_map[fieldId]][row[map[fieldId]]]) {
          if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "NOT_STARTED"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.$t("canvas.status.st1")})`
              : "";
          } else if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "IN_PROGRESS"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.this.$t("canvas.status.st2")})`
              : "";
          } else if (
            this[store_map[fieldId]][row[map[fieldId]]].status == "ENDED"
          ) {
            return row[map[fieldId]]
              ? this[store_map[fieldId]][row[map[fieldId]]] &&
                  `${
                    this[store_map[fieldId]][row[map[fieldId]]].name
                  }(${this.$t("canvas.status.st3")})`
              : "";
          }
        }

        return row[map[fieldId]]
          ? this[store_map[fieldId]][row[map[fieldId]]] &&
              this[store_map[fieldId]][row[map[fieldId]]].name
          : "";
      } else {
        const arr = [];
        row[map[fieldId]] &&
          row[map[fieldId]].forEach((item) => {
            if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "baseLine"
            ) {
              // 基线
              if (this[store_map[fieldId]][item].status == "NOT_STARTED") {
                // 未开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st1"
                      )})`
                    )
                  : "";
                // this[store_map[fieldId]][item]
                // ? arr.push(
                //     `${this[store_map[fieldId]][item].name}(${
                //       this[store_map[fieldId]][item].status
                //     })`
                //   )
                // : "";
              } else if (
                this[store_map[fieldId]][item].status == "ALREADY_STARTED"
              ) {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st4"
                      )})`
                    )
                  : "";
              } else if (this[store_map[fieldId]][item].status == "COMPLETE") {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st3"
                      )})`
                    )
                  : "";
              }
            } else if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "version"
            ) {
              // 版本
              if (this[store_map[fieldId]][item].status) {
                // 已发布
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st5"
                      )})`
                    )
                  : "";
              } else {
                // 未发布
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st6"
                      )})`
                    )
                  : "";
              }
            } else if (
              this[store_map[fieldId]][item] &&
              store_map[fieldId] == "testPlan"
            ) {
              // 测试计划
              if (this[store_map[fieldId]][item].status == "NOT_STARTED") {
                // 未开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st1"
                      )})`
                    )
                  : "";
              } else if (
                this[store_map[fieldId]][item].status == "IN_PROGRESS"
              ) {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st2"
                      )})`
                    )
                  : "";
              } else if (this[store_map[fieldId]][item].status == "COMPLETE") {
                // 已开始
                this[store_map[fieldId]][item]
                  ? arr.push(
                      `${this[store_map[fieldId]][item].name}(${this.$t(
                        "canvas.status.st3"
                      )})`
                    )
                  : "";
              }
            }
          });
        return arr.join(", ");
      }
    },
    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "Key") {
        return "color: #FFC300;font-weight: 700;border: none;"; // 修改的样式
      } else {
        return "color: #263281;font-weight: 700;border: none;";
      }
    },
    get_user_name(params) {
      let newarr = [];
      typeof params === "object" && params
        ? params.forEach((item) => {
            newarr.push(this.matchUserNickname(item, ""));
          })
        : newarr.push(this.matchUserNickname(params, ""));
      return newarr;
    },
    get_cus_value(row, id) {
      let value = "";
      row.content &&
        row.content.forEach((item) => {
          if (item.fieldId === id) {
            if (item.extraData && item.extraData.length) {
              if (item.fieldType === "SELECT") {
                item.extraData.forEach((extra) => {
                  if (extra.value === item.value) {
                    value = extra.label;
                  }
                });
              } else if (item.fieldType === "SELECT_MULTI") {
                item.value ? (value = item.value.join("，")) : (value = "");
              }
            } else if (
              item.fieldType === "MULTI_USER_SELECT" ||
              item.fieldType === "SINGLE_USER_SELECT"
            ) {
              value = item.value
                ? this.get_user_name(item.value).join("，")
                : "";
            } else if (item.fieldType === "DATE") {
              value = item.value
                ? new Date(item.value).Format("yyyy-MM-dd")
                : "";
            } else if (item.fieldType === "LABEL") {
              item.value ? (value = item.value.join("，")) : (value = "");
            } else {
              value = item.value;
            }
          }
        });
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.node_list {
  height: 100%;
  margin: 0px 20px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  height: fit-content;
}
.clickable-rows {
  background: white;
  overflow-x: auto;
}
.my-page {
  width: 100%;
  text-align: left;
  margin-top: 20px;
}
</style>
