<template>
  <div class="data-board-wrap">
    <div v-if="!chartOptions.length">
      <nodata
        v-if="chartOptions.length == 0"
        @changeDialogShow="changeDialogShow"
      />
    </div>
    <div v-else>
      <hasdata
        ref="hasdata"
        :chart-options="chartOptions"
        :chart-sources="chartSources"
        :field-map="fieldMap"
        :field-list="fieldList"
        @changeDialogShow="changeDialogShow"
        @get_dashboard_select="get_dashboard_select"
      ></hasdata>
    </div>
    <addBoardDialog
      :dialog-show="dialogShow"
      @changeDialogShow="changeDialogShow"
      @get_dashboard_select="get_dashboard_select"
      @addChartSucess="addChartSucess"
    ></addBoardDialog>
  </div>
</template>

<script>
import nodata from "./components/nodata.vue";
import hasdata from "./components/hasdata.vue";
import addBoardDialog from "./components/addBoardDialog.vue";
import {
  get_dashboard_select,
  get_node_fields,
} from "@/network/dataChart/index.js";
import { condition_get } from "@/network/nodePage/index.js";
export default {
  components: {
    nodata,
    hasdata,
    addBoardDialog,
  },
  props: {},
  data() {
    return {
      // 新增数据报表弹窗是否显示
      dialogShow: false,
      //下拉框内容
      chartOptions: [],
      // 所有字段类型
      fieldMap: null,
      fieldList: [],
      chartSources: [],
    };
  },
  mounted() {
    this.get_dashboard_select();
    this.getNodeFields();
    this.get_datasource();
  },
  methods: {
    // 获取数据源类型
    get_datasource() {
      this.chartSources = [];
      condition_get(this.get_pid())
        .then((res) => {
          res.forEach((item) => {
            this.chartSources = [
              ...this.chartSources,
              ...item.nodeQueryConditionDtoList,
            ];
          });
        })
        .catch((e) => console.log(e));
    },
    // 获取所有字段
    getNodeFields() {
      get_node_fields(this.get_pid(), [
        "MULTI_USER_SELECT",
        "SINGLE_USER_SELECT",
        "SELECT",
        "SELECT_MULTI",
        "STATUS",
        "LABEL",
      ]).then((res) => {
        this.fieldList = res;
        let map = new Map();
        res.map((item) => {
          map.set(item.fieldId, item.name);
        });
        this.fieldMap = map;
      });
    },
    // 获取所有测试计划下拉框
    async get_dashboard_select(callBack) {
      await get_dashboard_select(this.get_pid())
        .then((res) => {
          this.chartOptions = [...res];
          this.$nextTick(() => {
            callBack ? callBack() : "";
          });
        })
        .catch((e) => console.log(e));
    },
    // 成功新增图表
    addChartSucess(charId) {
      this.$refs.hasdata.changeChart(charId);
    },
    // 新增弹窗是否展示事件
    changeDialogShow(show) {
      this.dialogShow = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.data-board-wrap {
  height: 100%;
  & > div {
    height: 100%;
  }
}
</style>
