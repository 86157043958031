<template>
  <div>
    <!--    <div class="top">-->
    <!-- 左上角选择框 -->
    <el-row type="flex" class="datachart_page_topbar">
      <el-col
        style="display: flex; justify-content: flex-start; border-radius: 8px"
      >
        <el-select
          v-if="!isEditing"
          v-model="currentSelect"
          size="small"
          filterable
          popper-class="select_own"
          @change="changeChart"
        >
          <el-option key="add_new" class="datachart-add-option" value="">
            <div
              class="datachart-add"
              style="
                width: 100%;
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;
              "
              @click.stop="addChart"
            >
              <i class="iconfont icon-add"></i>
            </div>
          </el-option>
          <el-option
            v-for="item in chartOptions"
            :key="item.dashboardId"
            :label="item.name"
            :value="item.dashboardId"
          >
          </el-option>
        </el-select>
        <el-input
          v-if="isEditing"
          v-model="chartDetail.name"
          style="width: 30%"
          size="small"
          @change="edit_dashboard_name"
        >
        </el-input>
      </el-col>
      <el-col class="datachart_page_topbar_btns">
        <span>
          <el-button
            v-if="!isEditing && isEditor"
            type="primary"
            size="mini"
            @click="edit"
          >
            <i class="iconfont icon-branch_edit" style="margin-right: 5px"></i
            >{{ $t("btn.editBtn") }}
          </el-button>
        </span>

        <span>
          <el-button
            v-if="isEditing"
            type="info"
            size="mini"
            @click="delDialog = true"
          >
            <i class="iconfont icon-delete" style="margin-right: 5px"></i
            >{{ $t("btn.deleteBtn") }}
          </el-button>
        </span>

        <span>
          <el-button v-if="isEditing" type="info" size="mini" @click="saveEdit">
            <i class="iconfont icon-exit" style="margin-right: 5px"></i
            >{{ $t("btn.exitEditBtn") }}
          </el-button>
        </span>
      </el-col>
    </el-row>

    <el-collapse-transition>
      <div v-show="show_top">
        <!-- 上半部分 -->
        <div id="box-card-top" class="box-card-top">
          <el-row type="flex" class="box-card-top-row">
            <el-col :span="5">
              <el-col :span="8" class="box-card-top-row-label"
                >{{ $t("chart.basicInfo.creator") }}:</el-col
              >
              <el-col :span="15" class="box-card-top-row-value">{{
                matchUserNickname(chartDetail.createdBy)
              }}</el-col>
            </el-col>
            <el-col :span="6">
              <el-col :span="8" class="box-card-top-row-label"
                >{{ $t("chart.basicInfo.createTime") }}:</el-col
              >
              <el-col :span="15" class="box-card-top-row-value">{{
                chartDetail.createdAt
              }}</el-col>
            </el-col>

            <el-col :span="7">
              <el-col :span="8" class="box-card-top-row-label"
                >{{ $t("chart.basicInfo.readAuthority") }}:</el-col
              >
              <el-col
                v-show="isEditing"
                :span="15"
                class="box-card-top-row-select"
              >
                <Permission
                  :groupall="user_group"
                  :is_view="true"
                  :result="viewPermissions"
                  :change="changeViewPermissions"
                  :is-editing="isEditing"
                  :option_children="option_children"
                  @getreadList="getRead"
                />
              </el-col>
              <el-tooltip
                :disabled="viewPermissions.length < 3"
                effect="dark"
                placement="top"
              >
                <div slot="content">
                  <span>
                    <span v-for="(item, index) in viewPermissions" :key="index"
                      >{{ name_span(item)
                      }}<i v-if="index !== viewPermissions.length - 1"
                        >，</i
                      ></span
                    >
                  </span>
                </div>
                <div>
                  <el-col
                    v-if="!isEditing"
                    :span="15"
                    class="box-card-top-row-select"
                  >
                    <span
                      v-for="(item, index) in viewPermissions"
                      :key="index"
                      class="bgc-blue"
                      >{{ name_span(item)
                      }}<i v-if="index !== viewPermissions.length - 1"
                        >，</i
                      ></span
                    >
                  </el-col>
                </div>
              </el-tooltip>
            </el-col>

            <el-col :span="7">
              <el-col :span="8" class="box-card-top-row-label"
                >{{ $t("chart.basicInfo.writeAuthority") }}:</el-col
              >
              <el-col
                v-show="isEditing"
                :span="15"
                class="box-card-top-row-select"
              >
                <Permission
                  :groupall="user_group"
                  :result="editPermissions"
                  :change="changeEditPermissions"
                  :is-editing="isEditing"
                  :option_children="option_children"
                  @geteditList="getEdit"
                />
              </el-col>
              <el-tooltip
                :disabled="editPermissions.length < 3"
                effect="dark"
                placement="top"
              >
                <div slot="content">
                  <span>
                    <span v-for="(item, index) in editPermissions" :key="index"
                      >{{ name_span(item)
                      }}<i v-if="index !== editPermissions.length - 1"
                        >，</i
                      ></span
                    >
                  </span>
                </div>
                <div>
                  <el-col
                    v-if="!isEditing"
                    :span="15"
                    class="box-card-top-row-select"
                  >
                    <span
                      v-for="(item, index) in editPermissions"
                      :key="index"
                      class="bgc-blue"
                      >{{ name_span(item)
                      }}<i v-if="index !== editPermissions.length - 1"
                        >，</i
                      ></span
                    >
                  </el-col>
                </div>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row type="flex" class="box-card-top-row">
            <el-col :span="1" class="box-card-top-row-label"
              >{{ $t("chart.basicInfo.desc") }}:</el-col
            >
            <el-col
              v-if="!isEditing"
              :span="23"
              class="box-card-top-row-content"
            >
              <el-scrollbar
                style="
                  max-height: 80px;
                  overflow: hidden scroll;
                  min-height: 100%;
                "
              >
                <div
                  style="
                    text-align: left !important;
                    font-size: 14px;
                    line-height: 20px;
                    text-indent: 0;
                  "
                >
                  {{ chartDetail.description }}
                </div>
              </el-scrollbar>
            </el-col>
            <el-col
              v-if="isEditing"
              :span="23"
              class="box-card-top-row-content"
            >
              <el-input
                v-model="chartDetail.description"
                resize="none"
                :autosize="{ maxRows: 4 }"
                class="box-card-top-row-content-textarea"
                type="textarea"
                :placeholder="$t('placeholder.input')"
              >
              </el-input>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-collapse-transition>
    <!--    </div>-->

    <div class="sprint_options_hide" @click="toggleShow">
      <span v-if="show_top"
        ><i class="iconfont icon-fold_up" style="color: #000" />
        {{ $t("switch.PutAway") }}
      </span>
      <span v-else
        ><i class="iconfont icon-fold_down" style="color: #000" />
        {{ $t("switch.Expand") }}</span
      >
    </div>

    <div class="add_button_class">
      <el-button
        v-show="isEditing"
        type="primary"
        size="mini"
        icon="el-icon-circle-plus-outline"
        @click="click_add_chart"
        >{{ $t("chart.addDiagramBtn") }}</el-button
      >
    </div>
    <addChartDialog
      :dialog-show="dialogShow"
      :field-list="fieldList"
      :is-editing-chart="false"
      :current-select="currentSelect"
      :chart-sources="chartSources"
      :chart-form="chartForm"
      @changeDialogShow="changeDialogShow"
      @partChangeChart="partChangeChart"
    ></addChartDialog>

    <!--    删除数据报表弹窗-->
    <el-dialog
      :title="$t('review.tip11')"
      :visible.sync="delDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="30%"
    >
      <div style="text-align: left">{{ $t("chartTop.sureDelete") }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="delete_dashboard">{{
          $t("btn.deleteBtn")
        }}</el-button>
        <el-button @click="delDialog = false">{{
          $t("review.tip13")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Permission from "./Permission.vue";
import { mapGetters } from "vuex";
import {
  get_dashboard_detail,
  edit_dashboard,
  delete_dashboard,
} from "@/network/dataChart/index.js";
import { get_single_group } from "@/network/addGroup/index.js";
import addChartDialog from "./addChartDialog.vue";
export default {
  components: {
    Permission,
    addChartDialog,
  },
  props: {
    fieldMap: {
      type: Map,
      default() {
        return new Map();
      },
    },
    chartSources: {
      type: Array,
      default() {
        return [];
      },
    },
    chartOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      user_group: [],
      editisNull: [],
      readAll: {
        readlist: [],
        readGrouplist: [],
      },
      editAll: {
        editlist: [],
        edditGrouplist: [],
      },
      //  展开收起
      show_top: true,
      //  当前chartid
      currentSelect: null,
      // 图表格详细信息
      chartDetail: {},
      //  是否编辑中
      isEditing: false,
      viewPermissions: [],
      editPermissions: [],
      is_dashboard_name_changed: false, // 左上角dashboard名字是否被修改
      private_editor: "",
      // 是否显示添加图表
      dialogShow: false,
      delDialog: false,
      // 传入弹窗的数据
      chartForm: {},
    };
  },
  computed: {
    ...mapGetters([
      "userAccountId",
      "user_list",
      "project_user_list",
      "user_group_list",
    ]),
    isEditor() {
      let flag = false;
      if (!this.editPermissions) {
        flag = false;
      }
      if (this.editPermissions[0] && this.editPermissions[0][0] === "PUBLIC") {
        flag = true;
      } else if (
        this.editPermissions[0] &&
        this.editPermissions[0][0] === "PRIVATE"
      ) {
        if (this.private_editor === this.userAccountId) {
          flag = true;
        }
      } else {
        for (let item of this.editPermissions) {
          if (item[1] == "RESTRICTED_USERGROUP") {
            this.user_group.map((tag) => {
              if (tag.groupId == item[2]) {
                if (tag.accountIdList.includes(this.userAccountId)) {
                  flag = true;
                }
              }
            });
          } else {
            if (item[2] === this.userAccountId) {
              flag = true;
            }
          }
        }
      }
      return flag;
    },
    option_children() {
      const arr = [];
      Object.values(this.project_user_list).forEach((item) => {
        if (!item.deleted) {
          arr.push({ value: item.accountId, label: item.nickname });
        }
      });
      return arr;
    },
  },
  watch: {},
  mounted() {
    this.firstSelectChart();
  },
  created() {
    this.user_group = Object.values(this.user_group_list);
  },
  methods: {
    //获取编辑查看
    getRead(arr) {
      this.readAll.readlist = [];
      this.readAll.readGrouplist = [];
      arr.map((item) => {
        if (item[1] == "RESTRICTED_USER") {
          this.readAll.readlist.push(item[2]);
        } else if (item[1] == "RESTRICTED_USERGROUP") {
          this.readAll.readGrouplist.push(item[2]);
        }
      });
    },
    getEdit(arr) {
      this.editAll.editlist = [];
      this.editAll.edditGrouplist = [];
      this.editisNull = arr;
      arr.map((item) => {
        if (item[1] == "RESTRICTED_USER") {
          if (item[2] != null) {
            this.editAll.editlist.push(item[2]);
          }
        } else if (item[1] == "RESTRICTED_USERGROUP") {
          if (item[2] != null) {
            this.editAll.edditGrouplist.push(item[2]);
          }
        }
      });
    },
    // 默认选中
    firstSelectChart(flag) {
      if (
        this.$route.params.cur_dashboard == "enterOwn" ||
        flag === "enterOwn"
      ) {
        // 从主菜单点击进入
        this.chartOptions.length
          ? this.changeChart(
              this.chartOptions[0].dashboardId
            )
          : "";
        return;
      } else {
        // 通过路由传参进入
        // 判断数据报表权限
        const userPermissions = this.chartOptions.some((item) => {
          return item.dashboardId === this.$route.params.cur_dashboard;
        });
        if (!userPermissions) {
          // 此时无权限
          this.$message({
            message: this.$t("chartTop.noPermission"),
            type: "error",
            duration: 5 * 1000,
          });
          this.$router.push(`/${this.get_pid()}/home/my_file`);
        } else {
          this.changeChart(this.$route.params.cur_dashboard);
        }
      }
    },
    // 收起展开
    toggleShow() {
      this.show_top = !this.show_top;
    },
    // 新增图表
    addChart() {
      this.$emit("changeDialogShow", true);
    },
    midTogename(arr, flag) {
      if (arr[1] === "RESTRICTED_USER") {
        return this.matchUserNickname(arr[2], flag);
      } else {
        const listGroup = Object.values(this.user_group_list);
        for (let item of listGroup) {
          if (item.groupId === arr[2]) {
            return item.groupName;
          }
        }
      }
    },

    // 显示人名
    name_span(item) {
      return item[0] === "RESTRICTED"
        ? this.midTogename(item, "")
        : item[0] === "PRIVATE"
        ? this.$t("chart.basicInfo.authorityList.private")
        : this.$t("chart.basicInfo.authorityList.all");
    },
    // 更改查看权限
    changeViewPermissions(params) {
      this.viewPermissions = [...params];
    },
    // 更改编辑权限
    changeEditPermissions(params) {
      this.editPermissions = [...params];
    },
    // 选中chart
    changeChart(id) {
      this.currentSelect = id;
      this.$router.push({ name: "chart", params: { cur_dashboard: id } });
      this.chart_data = [];
      // 获取dashboard详情并对数据结构进行转化
      get_dashboard_detail(this.get_pid(), id).then((res) => {
        this.chartDetail = { ...res };
        this.$emit("updateDashboardId", this.chartDetail.dashboardId || "");
        this.$emit("updateChartDtos", this.chartDetail.chartDtos || []);
        // 查看权限
        if (
          res.readAuthority === "RESTRICTED" &&
          (res.reader.length > 0 || res.readerGroup.length > 0)
        ) {
          this.viewPermissions = res.reader
            .map((item) => ["RESTRICTED", "RESTRICTED_USER", item])
            .concat(
              res.readerGroup.map((item) => [
                "RESTRICTED",
                "RESTRICTED_USERGROUP",
                item,
              ])
            );
        } else {
          this.viewPermissions = [[res.readAuthority]];
        }
        this.getRead(this.viewPermissions);
        // 编辑权限
        if (
          res.editAuthority === "RESTRICTED" &&
          (res.editor.length > 0 || res.editorGroup.length > 0)
        ) {
          this.editPermissions = res.editor
            .map((item) => ["RESTRICTED", "RESTRICTED_USER", item])
            .concat(
              res.editorGroup.map((item) => [
                "RESTRICTED",
                "RESTRICTED_USERGROUP",
                item,
              ])
            );
        } else if (res.editAuthority === "PRIVATE") {
          this.private_editor = res.editor[0];
          this.editPermissions = [[res.editAuthority]];
        } else {
          this.editPermissions = [[res.editAuthority]];
        }
        this.getEdit(this.editPermissions);
      });
    },
    //编辑
    edit() {
      this.isEditing = !this.isEditing;
      this.$emit("updateIsEditing", this.isEditing);
      // 切换编辑状态的时候要根据表头高度resize滚动区
      // this.scroll_resize();
    },
    // 保存编辑
    saveEdit() {
      this.$nextTick(() => {
        this.isEditing = !this.isEditing;
        this.$emit("updateIsEditing", this.isEditing);
        if (this.editisNull.length == 0) {
          this.isEditing = true;
          this.$emit("updateIsEditing", this.isEditing);
          this.$message({
            type: "error",
            message: this.$t("chart.message.warn2"),
          });
        } else {
          // 将viewPermissions与readAuthority等字段转化
          let param;
          if (!this.viewPermissions.length) {
            param = {
              ...this.chartDetail,
              readAuthority: "RESTRICTED",
              reader: [],
              readerGroup: [],
              editAuthority: this.editPermissions[0][0],
              editor: this.editAll.editlist,
              editorGroup: this.editAll.edditGrouplist,
            };
          } else {
            param = {
              ...this.chartDetail,
              readAuthority: this.viewPermissions[0][0],
              reader: this.readAll.readlist,
              readerGroup: this.readAll.readGrouplist,
              editAuthority: this.editPermissions[0][0],
              editor: this.editAll.editlist,
              editorGroup: this.editAll.edditGrouplist,
            };
          }
          edit_dashboard(this.get_pid(), this.currentSelect, param)
            .then(() => {
              if (param.editAuthority === "PRIVATE") {
                this.private_editor = param.createdBy;
              }
              if (this.is_dashboard_name_changed) {
                this.$emit("get_dashboard_select");
              }
              this.$message({
                type: "success",
                message: this.$t("chart.message.saveSuccess"),
              });

              this.changeChart(this.$route.params.cur_dashboard);
            })
            .catch((e) => {});
        }
      });
    },
    //删除dashboard
    delete_dashboard() {
      this.isEditing = false;
      this.delDialog = false;
      this.$emit("updateIsEditing", this.isEditing);
      delete_dashboard(this.get_pid(), this.currentSelect)
        .then(() => {
          // 回显下拉框,让路由从'enterOwn'进入
          this.$emit("get_dashboard_select", () => {
            this.firstSelectChart("enterOwn");
          });
          this.$message({
            type: "success",
            message: this.$t("chart.message.deleteSuccess"),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("chart.message.deleteError"),
          });
        });
    },
    // 图表滚动区域resize
    scroll_resize() {
      const board_top = document.getElementById("box-card-top");
      const board_top_height = this.show_top ? board_top.offsetHeight : 0;
      const window_height =
        document.getElementById("datachart_page").offsetHeight;
      let scroll_height =
        board_top_height === 0
          ? window_height - board_top_height - 40
          : window_height - board_top_height - 80;
      let scroll_dom = document.getElementById("scroll_wrap");
      scroll_dom.style.height = scroll_height + "px";
    },
    // 修改左上角名称
    edit_dashboard_name() {
      this.is_dashboard_name_changed = true;
    },
    // 添加图表
    click_add_chart() {
      this.chartForm = {
        statisticObject: "NODE",
        queryConditionId: "all",
        file_queryConditionId: "all",
      };
      this.dialogShow = true;
    },
    // 添加图表弹窗切换显示状态
    changeDialogShow(flag) {
      this.dialogShow = flag;
    },
    partChangeChart(id, type) {
      this.$emit("partChangeChart", id, type);
    },
  },
};
</script>

<style lang="scss" scoped>
.datachart_page {
  height: 100%;
  &_topbar {
    display: flex;
    justify-content: space-between;
    &_btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      button {
        padding: 6px 10px;
        font-size: 16px;
        margin-left: 10px;
      }
    }
  }
}
.bgc-blue {
  color: rgba(42, 130, 228, 1);
  font-size: 14px;
}
.box-card {
  &-top {
    margin: 1vw 0 0 0;
    border-radius: 8px 8px 0 0;
    background-color: white;
    padding: 20px 20px 10px 20px;
    &-row {
      text-align: left;
      &-label {
        font-size: 14px;
        color: rgba(128, 128, 128, 1);
        font-weight: bold;
        word-break: keep-all;
      }
      &-value {
        font-size: 14px;
        color: rgba(128, 128, 128, 1);
        transform: translateY(1px);
      }
      &-content {
        white-space: pre-wrap;
        font-size: 14px;
        word-break: break-all;
        text-align: start;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        ::-webkit-scrollbar {
          width: 0 !important;
        }
        color: rgba(128, 128, 128, 1);
        ::v-deep .el-scrollbar__wrap {
          overflow: hidden;
          margin-right: 0 !important;
          margin-bottom: 0 !important;
          height: 100% !important;
        }
        &-textarea {
          font-size: 14px;
          color: rgba(128, 128, 128, 1);
          :first-child {
            padding: 0px;
          }
        }
      }
      &-select {
        font-size: 14px;
        overflow: hidden;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /*限制文本行数*/
        -webkit-box-orient: vertical;
        &-main {
          width: 100%;
        }
      }
      &-ellipsis {
        position: relative;
        left: -10px;
        top: 30px;
      }
    }
  }
  &-main {
    margin: 0.05vw;
  }
}
.sprint_options_hide {
  background-color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 0 0 8px 8px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #f9fafc;
    color: #409eff;
  }
}
.add_button_class {
  text-align: right;
  margin: 10px 0 20px 0;
}
</style>
<style lang="scss">
.select_own .el-select-dropdown__list {
  padding: 0;
  margin: 0;
  li:nth-child(1) {
    background-color: rgba(77, 160, 255, 0.35);
    border-radius: 6px 6px 0px 0px;
  }
}
</style>
