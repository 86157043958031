<template>
  <div>
    <el-dialog
      :visible.sync="dialogShow"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
      :title="$t('chart.addDiagramForm.title')"
      custom-class="add_datachart"
      append-to-body
    >
      <el-form
        ref="addDatachartForm"
        label-position="left"
        label-width="100px"
        :model="chartForm"
        :rules="rules"
      >
        <el-form-item :label="$t('chart.addDiagramForm.name')" prop="name">
          <el-input v-model="chartForm.name"> </el-input>
        </el-form-item>
        <el-form-item
          :label="$t('chart.addDiagramForm.datatype')"
          :required="true"
        >
          <el-radio-group
            v-model="chartForm.statisticObject"
            size="small"
            style="width: 100%; text-align: left"
          >
            <el-radio label="NODE" border>{{
              $t("chart.addDiagramForm.node")
            }}</el-radio>
            <el-radio label="FILE" border>{{
              $t("chart.addDiagramForm.file")
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 图表类型 -->
        <el-form-item :label="$t('chart.addDiagramForm.type')" prop="type">
          <el-select v-model="chartForm.type" style="width: 100%" filterable>
            <el-option
              v-for="item in charts_types"
              v-show="
                !(
                  chartForm.statisticObject == 'FILE' &&
                  (item.type == 'VMODEL_CHART' ||
                    item.type == 'WMODEL_CHART' ||
                    item.type == 'TWO_DIMENSION_COVERAGE')
                )
              "
              :key="item.type"
              :label="item.label"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <!-- charts_types_without_vmodel -->
          <div
            v-if="
              chartForm.type === 'STATUS_DURATION' ||
              chartForm.type === 'STATUS_TIMES'
            "
            style="
              background-color: rgba(228, 235, 241, 0.62);
              margin-top: 10px;
              font-size: 12px;
              font-color: rgba(128, 128, 128, 1);
              padding: 10px;
              border-radius: 4px;
              width: 100%;
              line-height: 20px;
              text-align: left;
              display: flex;
            "
          >
            <div>
              <i
                class="iconfont icon-help"
                style="font-size: 14px; margin-right: 4px"
              ></i>
            </div>
            <div>
              {{
                chartForm.type === "STATUS_DURATION"
                  ? $t("chart.message.ms2")
                  : $t("chart.message.ms1")
              }}
            </div>
          </div>
        </el-form-item>

        <div v-if="chartForm.statisticObject == 'NODE'">
          <!-- 筛选条件 -->
          <el-form-item :label="$t('file.dialog.condition')" :required="true">
            <el-select
              ref="node_source_elSelect"
              v-model="chartForm.queryConditionId"
              filterable
              style="width: 100%"
              @visible-change="get_node_condition_fun"
            >
              <el-option value="all" :label="$t('chart.allData')"> </el-option>
              <el-option
                v-for="item in nodeSources"
                v-show="item.createdBy === userAccountId"
                :key="item.id"
                :label="item.name"
                :value="item.queryConditionId"
              >
              </el-option>
              <el-option value="add" label="" class="datasource-add-option">
                <div style="width: 100%" @click.stop="add_datasource">
                  <i class="iconfont icon-add"></i>
                </div>
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 柱状图 -->
          <el-form-item
            v-show="
              chartForm.type &&
              chartForm.type !== 'VMODEL_CHART' &&
              chartForm.type !== 'WMODEL_CHART' &&
              chartForm.type !== 'FILTER_LIST' &&
              chartForm.type !== 'STATUS_DURATION' &&
              chartForm.type !== 'STATUS_TIMES' &&
              chartForm.type !== 'TWO_DIMENSION_TABLE' &&
              chartForm.type !== 'TWO_DIMENSION_COVERAGE'
            "
            :label="$t('chart.addDiagramForm.fieldSelection')"
            :required="true"
          >
            <el-select
              v-model="chartForm.fieldId"
              filterable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in charts_fields"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 层级 -->
          <el-form-item
            v-show="chartForm.type === 'TWO_DIMENSION_COVERAGE'"
            v-for="(item, index) in twoDCLevel"
            :key="index"
            :label="
              $t('chart.addDiagramForm.typeList.l') +
              (index + 1) +
              $t('chart.addDiagramForm.typeList.c')
            "
            :required="index < 1 ? true : false"
          >
            <el-select
              v-model="item.fileType"
              filterable
              :style="{
                width: index < 1 ? '100%' : '90%',
              }"
            >
              <el-option
                v-for="(item, index) in form_options"
                v-show="
                  twoDCLevel
                    .map((level) => {
                      return level.fileType;
                    })
                    .indexOf(item.fileTypeId) == -1
                "
                :key="index"
                :label="item.name"
                :value="item.fileTypeId"
              >
              </el-option>
            </el-select>
            <i
              v-show="index >= 1"
              class="iconfont icon-baseline-delete"
              style="cursor: pointer; color: #f56c6c; margin-left: 12px"
              @click="deleteLevel(index)"
            >
            </i>
          </el-form-item>
          <div v-show="chartForm.type === 'TWO_DIMENSION_COVERAGE'">
            <i
              class="iconfont icon-add"
              style="
                font-size: 25px;
                margin: 20px 0;
                cursor: pointer;
                color: rgb(48, 100, 143);
              "
              @click="addLevel"
            ></i>
          </div>
          <!-- 筛选结果 -->
          <el-form-item
            v-show="chartForm.type === 'FILTER_LIST'"
            :label="$t('chart.addDiagramForm.fieldSelection')"
            :required="false"
          >
            <el-select
              v-model="chartForm.frontExtraData"
              multiple
              filterable
              collapse-tags
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in charts_fields"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!--        选择二维数据报表时需选择X轴字段和Y轴字段-->
          <!--        X轴-->
          <el-form-item
            v-show="chartForm.type === 'TWO_DIMENSION_TABLE'"
            :label="$t('dataChart.XAxisFields')"
            :required="true"
          >
            <el-select
              v-model="chartForm.fieldId"
              filterable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in charts_fields"
                v-show="item.fieldId !== chartForm.fieldId02"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--        Y轴-->
          <el-form-item
            v-show="chartForm.type === 'TWO_DIMENSION_TABLE'"
            :label="$t('dataChart.YAxisFields')"
            :required="true"
          >
            <el-select
              v-model="chartForm.fieldId02"
              filterable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in charts_fields"
                v-show="item.fieldId !== chartForm.fieldId"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div v-else>
          <!-- 筛选条件 -->
          <el-form-item
            v-show="
              chartForm.type !== 'VMODEL_CHART' ||
              chartForm.type !== 'WMODEL_CHART'
            "
            :label="$t('file.dialog.condition')"
            :required="true"
          >
            <el-select
              ref="file_source_elSelect"
              v-model="chartForm.file_queryConditionId"
              filterable
              style="width: 100%"
              @visible-change="get_file_condition_fun"
            >
              <el-option value="all" :label="$t('chart.allData')"> </el-option>
              <el-option
                v-for="item in fileSources"
                v-show="item.createdBy === userAccountId"
                :key="item.id"
                :label="item.name"
                :value="item.queryConditionId"
              >
              </el-option>

              <el-option value="add" label="" class="datasource-add-option">
                <div style="width: 100%" @click.stop="add_filesource">
                  <i class="iconfont icon-add"></i>
                </div>
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 字段选择 -->
          <!-- 柱状图 -->
          <el-form-item
            v-show="
              chartForm.type &&
              chartForm.type !== 'VMODEL_CHART' &&
              chartForm.type !== 'WMODEL_CHART' &&
              chartForm.type !== 'FILTER_LIST' &&
              chartForm.type !== 'STATUS_DURATION' &&
              chartForm.type !== 'STATUS_TIMES' &&
              chartForm.type !== 'TWO_DIMENSION_TABLE'
            "
            :label="$t('chart.addDiagramForm.fieldSelection')"
            :required="true"
          >
            <el-select
              v-model="chartForm.file_fieldId"
              filterable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in file_fields"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 筛选结果 -->
          <el-form-item
            v-show="chartForm.type === 'FILTER_LIST'"
            :label="$t('chart.addDiagramForm.fieldSelection')"
            :required="false"
          >
            <el-select
              v-model="chartForm.file_frontExtraData"
              multiple
              filterable
              collapse-tags
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in file_fields"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <!--        选择二维数据报表时需选择X轴字段和Y轴字段-->
          <!--        X轴-->
          <el-form-item
            v-show="chartForm.type === 'TWO_DIMENSION_TABLE'"
            :label="$t('dataChart.XAxisFields')"
            :required="true"
          >
            <el-select
              v-model="chartForm.file_fieldId"
              filterable
              clearable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in file_fields"
                v-show="item.fieldId !== chartForm.file_fieldId02"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!--        Y轴-->
          <el-form-item
            v-show="chartForm.type === 'TWO_DIMENSION_TABLE'"
            :label="$t('dataChart.YAxisFields')"
            :required="true"
          >
            <el-select
              v-model="chartForm.file_fieldId02"
              filterable
              clearable
              style="width: 100%"
              @change="change"
            >
              <el-option
                v-for="item in file_fields"
                v-show="item.fieldId !== chartForm.file_fieldId"
                :key="item.fieldId"
                :label="item.name"
                :value="item.fieldId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="info" @click="cancelChartDialog">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          v-if="!isEditingChart"
          type="primary"
          class="button-confirm"
          @click="add_chart"
          >{{ $t("btn.newBtn") }}</el-button
        >
        <el-button
          v-else
          type="primary"
          class="button-confirm"
          @click="update_chart"
          >{{ $t("btn.saveBtn") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  add_chart,
  get_chart_by_id,
  add_vmodel_chart,
  edit_chart,
  edit_vmodel_chart,
  get_pay_vmodel,
  get_expired_vmodel,
} from "@/network/dataChart/index.js";
import { get_pay_wmodel, get_expired_wmodel } from "@/network/wmodel/index.js";
import { condition_get } from "@/network/home/index.js";
import { condition_get as node_condition_get } from "@/network/nodePage/index.js";
export default {
  props: {
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
    dialogShow: {
      type: Boolean,
      default: false,
    },
    chartForm: {
      type: Object,
      default() {
        return {};
      },
    },
    isEditingChart: {
      type: Boolean,
      default: false,
    },
    currentSelect: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 图表类型
      charts_types: [
        { type: "BAR", label: this.$t("chart.addDiagramForm.typeList.bar") },
        { type: "LINE", label: this.$t("chart.addDiagramForm.typeList.line") },
        { type: "PIE", label: this.$t("chart.addDiagramForm.typeList.pie") },
        {
          type: "RADAR",
          label: this.$t("chart.addDiagramForm.typeList.radar"),
        },
        {
          type: "TWO_DIMENSION_TABLE",
          label: this.$t("chart.addDiagramForm.typeList.twoDTable"),
        },
        {
          type: "TWO_DIMENSION_COVERAGE",
          label: this.$t("chart.addDiagramForm.typeList.twoDCoverage"),
        },
        {
          type: "STATUS_DURATION",
          label: this.$t("chart.addDiagramForm.typeList.duration"),
        },
        {
          type: "STATUS_TIMES",
          label: this.$t("chart.addDiagramForm.typeList.times"),
        },
        {
          type: "FILTER_LIST",
          label: this.$t("chart.addDiagramForm.typeList.condition"),
        },
        {
          type: "VMODEL_CHART",
          label: this.$t("chart.addDiagramForm.typeList.vmodel"),
        },
        {
          type: "WMODEL_CHART",
          label: this.$t("chart.addDiagramForm.typeList.wmodel"),
        },
      ],
      filterFieldList: [...this.fieldList],
      rules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.name"),
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("placeholder.dropdown"),
            trigger: "change",
          },
        ],
      },
      fileSources: [],
      nodeSources: [],
      twoDCLevel: [
        {
          fileType: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userAccountId", "fileType"]),
    form_options() {
      return Object.values(this.fileType);
    },
    charts_fields() {
      if (this.chartForm.type === "FILTER_LIST") {
        this.addCreateAtToFilterList();
        return [...this.filterFieldList];
      }
      return [...this.fieldList];
    },
    file_fields() {
      let l = [];
      this.charts_fields.forEach((i) => {
        if (i.fieldName == "createdBy") {
          l.push(i);
        }
        if (i.fieldName == "fileTypeId") {
          l.push(i);
        }
        if (i.fieldName == "status") {
          l.push(i);
        }
      });
      return l;
    },
  },
  watch: {
    dialogShow: function () {
      this.$nextTick(() => {
        this.$refs["addDatachartForm"]
          ? this.$refs["addDatachartForm"].clearValidate()
          : "";
        this.twoDCLevel = [
          {
            fileType: "",
          },
        ];
      });
    },
    "chartForm.statisticObject": function () {
      if (this.chartForm.statisticObject == "FILE") {
        if (
          this.chartForm.type == "VMODEL_CHART" ||
          this.chartForm.type == "WMODEL_CHART"
        ) {
          this.chartForm.type = "";
        }
      }
      this.$nextTick(() => {
        this.$refs["addDatachartForm"]
          ? this.$refs["addDatachartForm"].clearValidate()
          : "";
      });
    },
  },
  created() {},
  mounted() {
    node_condition_get(this.get_pid()).then((res) => {
      this.nodeSources = [];
      res.forEach((item) => {
        this.nodeSources = [
          ...this.nodeSources,
          ...item.nodeQueryConditionDtoList,
        ];
      });
    });
    condition_get(this.get_pid()).then((res) => {
      this.fileSources = [];
      res.forEach((item) => {
        this.fileSources = [
          ...this.fileSources,
          ...item.fileQueryConditionDtoList,
        ];
      });
    });
  },
  methods: {
    makeLevel(twoDCLevel) {
      setTimeout(() => {
        this.twoDCLevel = twoDCLevel;
      });
    },
    addLevel() {
      this.twoDCLevel.push({
        fileType: "",
      });
    },
    deleteLevel(index) {
      this.twoDCLevel.splice(index, 1);
    },
    get_node_condition_fun(flag) {
      if (flag) {
        node_condition_get(this.get_pid()).then((res) => {
          this.nodeSources = [];
          res.forEach((item) => {
            this.nodeSources = [
              ...this.nodeSources,
              ...item.nodeQueryConditionDtoList,
            ];
          });
        });
      }
    },
    get_file_condition_fun(flag) {
      if (flag) {
        condition_get(this.get_pid()).then((res) => {
          this.fileSources = [];
          res.forEach((item) => {
            this.fileSources = [
              ...this.fileSources,
              ...item.fileQueryConditionDtoList,
            ];
          });
        });
      }
    },
    change() {
      //强制渲染
      this.$forceUpdate();
    },
    // 新增file数据源
    add_filesource() {
      this.$refs.file_source_elSelect.blur();
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/home/my_file`,
      });
      window.open(href, "_blank");
    },
    // 新增node数据源
    add_datasource() {
      this.$refs.node_source_elSelect.blur();
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/home/node`,
      });
      window.open(href, "_blank");
    },
    // 新建
    add_chart() {
      this.$refs["addDatachartForm"].validate((valid) => {
        if (valid) {
          if (this.chartForm.statisticObject == "NODE") {
            let param = { ...this.chartForm };
            // 校验
            // 前端设为all是方便校验 传给后端要传空字符串
            param.queryConditionId === "all"
              ? (param.queryConditionId = "")
              : "";
            // 如果是筛选结果
            param.type === "FILTER_LIST"
              ? (param.frontExtraData = JSON.stringify(param.frontExtraData))
              : "";
            if (param.type === "VMODEL_CHART") {
              //检测vmodel是否开通付费/过期
              // exp true: 已过期
              // exp false; has false: 未付费
              // exp false; has true: 已付费
              let expVmodel;
              let hasVmodel;
              get_expired_vmodel().then(async (r) => {
                expVmodel = r || false;
                if (!r) {
                  // 从未开通或开通未过期
                  get_pay_vmodel(this.get_pid()).then((res) => {
                    hasVmodel = res || false;
                    if (!expVmodel && hasVmodel) {
                      add_vmodel_chart(
                        this.get_pid(),
                        this.currentSelect,
                        param
                      )
                        .then((res) => {
                          if (
                            localStorage.getItem(
                              this.$route.params.cur_dashboard
                            )
                          ) {
                            let dashboardArray = JSON.parse(
                              localStorage.getItem(
                                this.$route.params.cur_dashboard
                              )
                            );
                            dashboardArray.unshift(res);
                            localStorage.setItem(
                              this.$route.params.cur_dashboard,
                              JSON.stringify(dashboardArray)
                            );
                          }
                          this.$emit(
                            "partChangeChart",
                            this.currentSelect,
                            "add"
                          );
                          this.$emit("changeDialogShow", false);
                        })
                        .catch((e) => {});
                    } else {
                      this.$message({
                        duration: 0,
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: `<span>${this.$t(
                          "certificate.Please"
                        )}<a href="${
                          window.location.origin
                        }/profile/certificate_management?target=vmodel" target="_blank" style="color: blue">${this.$t(
                          "certificate.CertificateManagement"
                        )}</a>${this.$t(
                          "certificate.InstallComponents"
                        )}</span>`,
                      });
                    }
                  });
                }
              });
            } else if (param.type === "WMODEL_CHART") {
              //检测vmodel是否开通付费/过期
              // exp true: 已过期
              // exp false; has false: 未付费
              // exp false; has true: 已付费
              let expVmodel;
              let hasVmodel;
              get_expired_wmodel().then(async (r) => {
                expVmodel = r || false;
                if (!r) {
                  // 从未开通或开通未过期
                  get_pay_wmodel(this.get_pid()).then((res) => {
                    hasVmodel = res || false;
                    if (!expVmodel && hasVmodel) {
                      add_vmodel_chart(
                        this.get_pid(),
                        this.currentSelect,
                        param
                      )
                        .then((res) => {
                          if (
                            localStorage.getItem(
                              this.$route.params.cur_dashboard
                            )
                          ) {
                            let dashboardArray = JSON.parse(
                              localStorage.getItem(
                                this.$route.params.cur_dashboard
                              )
                            );
                            dashboardArray.unshift(res);
                            localStorage.setItem(
                              this.$route.params.cur_dashboard,
                              JSON.stringify(dashboardArray)
                            );
                          }
                          this.$emit(
                            "partChangeChart",
                            this.currentSelect,
                            "add"
                          );
                          this.$emit("changeDialogShow", false);
                        })
                        .catch((e) => {});
                    } else {
                      this.$message({
                        duration: 0,
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: `<span>${this.$t(
                          "certificate.Please"
                        )}<a href="${
                          window.location.origin
                        }/profile/certificate_management?target=wmodel" target="_blank" style="color: blue">${this.$t(
                          "certificate.CertificateManagement"
                        )}</a>${this.$t(
                          "certificate.InstallComponents"
                        )}</span>`,
                      });
                    }
                  });
                }
              });
            } else {
              if (param.type === "TWO_DIMENSION_COVERAGE") {
                param.frontExtraData = this.twoDCLevel;
                //检测vmodel是否开通付费/过期
                // exp true: 已过期
                // exp false; has false: 未付费
                // exp false; has true: 已付费
                let expVmodel;
                let hasVmodel;
                get_expired_vmodel().then(async (r) => {
                  expVmodel = r || false;
                  if (!r) {
                    // 从未开通或开通未过期
                    get_pay_vmodel(this.get_pid()).then((res) => {
                      hasVmodel = res || false;
                      if (!expVmodel && hasVmodel) {
                        add_chart(this.get_pid(), this.currentSelect, param)
                          .then((res) => {
                            if (
                              localStorage.getItem(
                                this.$route.params.cur_dashboard
                              )
                            ) {
                              let dashboardArray = JSON.parse(
                                localStorage.getItem(
                                  this.$route.params.cur_dashboard
                                )
                              );
                              dashboardArray.unshift(res);
                              localStorage.setItem(
                                this.$route.params.cur_dashboard,
                                JSON.stringify(dashboardArray)
                              );
                            }
                            this.$emit(
                              "partChangeChart",
                              this.currentSelect,
                              "add"
                            );
                            this.$emit("changeDialogShow", false);
                          })
                          .catch((e) => {});
                      } else {
                        this.$message({
                          duration: 0,
                          showClose: true,
                          dangerouslyUseHTMLString: true,
                          message: `<span>${this.$t(
                            "certificate.Please"
                          )}<a href="${
                            window.location.origin
                          }/profile/certificate_management?target=vmodel" target="_blank" style="color: blue">${this.$t(
                            "certificate.CertificateManagement"
                          )}</a>${this.$t(
                            "certificate.InstallComponents"
                          )}</span>`,
                        });
                      }
                    });
                  }
                });
              } else {
                add_chart(this.get_pid(), this.currentSelect, param)
                  .then((res) => {
                    if (
                      localStorage.getItem(this.$route.params.cur_dashboard)
                    ) {
                      let dashboardArray = JSON.parse(
                        localStorage.getItem(this.$route.params.cur_dashboard)
                      );
                      dashboardArray.unshift(res);
                      localStorage.setItem(
                        this.$route.params.cur_dashboard,
                        JSON.stringify(dashboardArray)
                      );
                    }
                    this.$emit("partChangeChart", this.currentSelect, "add");
                    this.$emit("changeDialogShow", false);
                  })
                  .catch((e) => {});
              }
            }
          } else {
            let param = {
              queryConditionId:
                this.chartForm.file_queryConditionId == "all"
                  ? ""
                  : this.chartForm.file_queryConditionId,
              frontExtraData: this.chartForm.file_frontExtraData,
              name: this.chartForm.name,
              type: this.chartForm.type,
              fieldId: this.chartForm.file_fieldId,
              fieldId02: this.chartForm.file_fieldId02,
              statisticObject: this.chartForm.statisticObject,
            };

            // 校验
            //从二维更新到一维需删除fieldId02字段
            if (this.chartForm.type !== "TWO_DIMENSION_TABLE") {
              param.fieldId02 = "";
            }
            // 如果是筛选结果
            param.type === "FILTER_LIST"
              ? (param.frontExtraData = JSON.stringify(param.frontExtraData))
              : "";

            if (param.type === "VMODEL_CHART") {
              //检测vmodel是否开通付费/过期
              // exp true: 已过期
              // exp false; has false: 未付费
              // exp false; has true: 已付费
              let expVmodel;
              let hasVmodel;
              get_expired_vmodel().then(async (r) => {
                expVmodel = r || false;
                if (!r) {
                  // 从未开通或开通未过期
                  get_pay_vmodel(this.get_pid()).then((res) => {
                    hasVmodel = res || false;
                    if (!expVmodel && hasVmodel) {
                      add_vmodel_chart(
                        this.get_pid(),
                        this.currentSelect,
                        param
                      )
                        .then((res) => {
                          this.$emit(
                            "partChangeChart",
                            this.currentSelect,
                            "add"
                          );
                          this.$emit("changeDialogShow", false);
                        })
                        .catch((e) => {});
                    } else {
                      this.$message({
                        duration: 0,
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: `<span>${this.$t(
                          "certificate.Please"
                        )}<a href="${
                          window.location.origin
                        }/profile/certificate_management?target=vmodel" target="_blank" style="color: blue">${this.$t(
                          "certificate.CertificateManagement"
                        )}</a>${this.$t(
                          "certificate.InstallComponents"
                        )}</span>`,
                      });
                    }
                  });
                }
              });
            } else {
              add_chart(this.get_pid(), this.currentSelect, param)
                .then((res) => {
                  if (localStorage.getItem(this.$route.params.cur_dashboard)) {
                    let dashboardArray = JSON.parse(
                      localStorage.getItem(this.$route.params.cur_dashboard)
                    );
                    dashboardArray.unshift(res);
                    localStorage.setItem(
                      this.$route.params.cur_dashboard,
                      JSON.stringify(dashboardArray)
                    );
                  }
                  this.$emit("partChangeChart", this.currentSelect, "add");
                  this.$emit("changeDialogShow", false);
                })
                .catch((e) => {});
            }
          }
        } else {
          return false;
        }
      });
    },
    // 更新
    update_chart() {
      this.$refs["addDatachartForm"].validate((valid) => {
        if (valid) {
          let param = {};
          if (this.chartForm.statisticObject == "FILE") {
            param = {
              queryConditionId:
                this.chartForm.file_queryConditionId == "all"
                  ? ""
                  : this.chartForm.file_queryConditionId,
              frontExtraData: this.chartForm.file_frontExtraData,
              name: this.chartForm.name,
              type: this.chartForm.type,
              fieldId: this.chartForm.file_fieldId,
              fieldId02: this.chartForm.file_fieldId02,
              statisticObject: this.chartForm.statisticObject,
            };
          } else {
            param = {
              queryConditionId:
                this.chartForm.queryConditionId == "all"
                  ? ""
                  : this.chartForm.queryConditionId,
              frontExtraData: this.chartForm.frontExtraData,
              name: this.chartForm.name,
              type: this.chartForm.type,
              fieldId: this.chartForm.fieldId,
              fieldId02: this.chartForm.fieldId02,
              statisticObject: this.chartForm.statisticObject,
            };
          }

          //从二维更新到一维需删除fieldId02字段
          if (this.chartForm.type !== "TWO_DIMENSION_TABLE") {
            param.fieldId02 = "";
          }
          // 前端设为all是方便校验 传给后端要传空字符串
          param.queryConditionId === "all" ? (param.queryConditionId = "") : "";
          // 如果是筛选结果
          param.type === "FILTER_LIST"
            ? (param.frontExtraData = JSON.stringify(param.frontExtraData))
            : "";
          if (
            param.type === "VMODEL_CHART" &&
            !this.expVmodel &&
            this.hasVmodel
          ) {
            //检测vmodel是否开通付费/过期
            // exp true: 已过期
            // exp false; has false: 未付费
            // exp false; has true: 已付费
            let expVmodel;
            let hasVmodel;
            get_expired_vmodel().then(async (r) => {
              expVmodel = r || false;
              if (!r) {
                // 从未开通或开通未过期
                get_pay_vmodel(this.get_pid()).then((res) => {
                  hasVmodel = res || false;
                  if (!expVmodel && hasVmodel) {
                    add_vmodel_chart(this.get_pid(), this.currentSelect, param)
                      .then((res) => {
                        this.$emit(
                          "partChangeChart",
                          this.currentSelect,
                          "update"
                        );
                        this.$emit("changeDialogShow", false);
                      })
                      .catch((e) => {});
                  } else {
                    this.$message({
                      duration: 0,
                      showClose: true,
                      dangerouslyUseHTMLString: true,
                      message: `<span>${this.$t(
                        "certificate.Please"
                      )}<a href="${
                        window.location.origin
                      }/profile/certificate_management?target=vmodel" target="_blank" style="color: blue">${this.$t(
                        "certificate.CertificateManagement"
                      )}</a>${this.$t("certificate.InstallComponents")}</span>`,
                    });
                  }
                });
              }
            });
          } else {
            if (param.type === "VMODEL_CHART") {
              edit_vmodel_chart(
                this.get_pid(),
                this.currentSelect,
                this.chartForm.chartId,
                param
              )
                .then(() => {
                  get_chart_by_id(
                    this.get_pid(),
                    this.currentSelect,
                    this.chartForm.chartId
                  )
                    .then((res) => {
                      this.$emit(
                        "partChangeChart",
                        this.currentSelect,
                        "update"
                      );
                      this.$emit("changeDialogShow", false);
                    })
                    .catch((e) => {});
                })
                .catch((e) => {
                  this.loading = false;
                });
            } else if (param.type === "WMODEL_CHART") {
              edit_vmodel_chart(
                this.get_pid(),
                this.currentSelect,
                this.chartForm.chartId,
                param
              )
                .then(() => {
                  get_chart_by_id(
                    this.get_pid(),
                    this.currentSelect,
                    this.chartForm.chartId
                  )
                    .then((res) => {
                      this.$emit(
                        "partChangeChart",
                        this.currentSelect,
                        "update"
                      );
                      this.$emit("changeDialogShow", false);
                    })
                    .catch((e) => {});
                })
                .catch((e) => {
                  this.loading = false;
                });
            } else {
              edit_chart(
                this.get_pid(),
                this.currentSelect,
                this.chartForm.chartId,
                param
              )
                .then(() => {
                  get_chart_by_id(
                    this.get_pid(),
                    this.currentSelect,
                    this.chartForm.chartId
                  )
                    .then((res) => {
                      this.$emit(
                        "partChangeChart",
                        this.currentSelect,
                        "update"
                      );
                      this.$emit("changeDialogShow", false);
                    })
                    .catch((e) => {});
                })
                .catch((e) => {
                  this.loading = false;
                });
            }
          }
        } else {
          return false;
        }
      });
    },
    // 取消
    cancelChartDialog() {
      this.$emit("changeDialogShow", false);
    },
    // 给筛选列表加创建时间
    addCreateAtToFilterList() {
      this.filterFieldList = [...this.fieldList];
      const newItem = {
        extraData: null,
        fieldId: 4,
        fieldName: "createdAt",
        fieldType: "DATE",
        isCus: true,
        isCustom: true,
        label: this.$t("node.table.createdTime"),
        minWidth: 180,
        name: this.$t("node.table.createdTime"),
      };
      let ids = this.filterFieldList.map((item) => item.fieldId);
      if (!ids.includes(newItem.fieldId)) {
        this.filterFieldList.push(newItem);
      }
    },
  },
};
</script>

<style></style>
