<template>
  <div>
    <!-- 二维报表  如v模型wlb -->
    <div v-if="!expVmodel" class="vmodel_table">
      <el-table
        :data="vmodel_table_data"
        :cell-style="cellStyle"
        @cell-click="fn_cell_details"
      >
        <el-table-column
          label=""
          align="right"
          width="150"
          :show-overflow-tooltip="true"
        >
          <template slot="header" slot-scope="scope">
            <!-- 提示 -->
            <el-tooltip :content="scope.column.label">
              <span>{{ scope.column.label }}</span>
            </el-tooltip>
          </template>
          <!-- left vmodel_table_data中的name就是最左边的列 -->
          <el-table-column
            prop="name"
            label=""
            width="120"
            :show-overflow-tooltip="true"
          >
            <template slot="header" slot-scope="scope">
              <el-tooltip :content="scope.column.label">
                <span>{{ scope.column.label }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-for="(col, i) in vmodel_column_list"
          :key="i"
          :prop="col.prop"
          :label="col.label"
          :index="i"
          align="center"
          min-width="0"
          :show-overflow-tooltip="true"
        >
          <template slot="header" slot-scope="scope">
            <div @mouseover="onMouseOver('refName' + scope.$index)">
              <el-tooltip
                :disabled="isShowTooltip"
                :content="col.label"
                placement="top"
              >
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  <span :ref="'refName' + scope.$index">{{ col.label }}</span>
                </div>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-else style="margin-bottom: 20px">
      {{ $t("chart.message.ms10") }}
      <span style="color: blue; cursor: pointer" @click="ejectPrompt">{{
        $t("chart.message.ms11")
      }}</span>
    </div>
    <!-- 弹出框wlb -->
    <el-dialog
      :title="dialogVmodelTitle"
      width="50%"
      class="vmodel-dialog"
      :close-on-click-modal="false"
      :show-close="true"
      :visible.sync="dialogVmodel"
      append-to-body
    >
      <el-table
        :stripe="false"
        :header-cell-style="{ border: 'none' }"
        class="vmodel-table"
        :data="tableDataVmodel"
        :row-style="{ height: '70px' }"
        style="width: 100%; position: relative"
      >
        <el-table-column
          ref="vmodelRef"
          prop="key"
          class="first-col-vmodel"
          align="center"
          label="Key"
          width="160"
        >
          <template slot-scope="scope">
            <span
              class="key_vmodel"
              style="text-align: left; cursor: pointer"
              @click="rowClicked(scope.row)"
            >
              <em>{{ scope.row.key }}</em>
            </span>
            <i
              style="
                font-size: 15px;
                margin-left: 10px;
                cursor: pointer;
                color: rgb(48, 100, 143);
              "
              class="iconfont icon-node_link"
              @click="nodeLink(scope.row)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column :label="$t('chart.message.ms31')">
          <template slot-scope="scoped">
            <el-tooltip
              effect="dark"
              :content="scoped.row.topic"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p
                style="
                  margin: 0;
                  width: 480px;
                  text-align: left;
                  font-size: 18px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: rgba(38, 50, 129, 1);
                "
                @mouseenter="visibilityChange($event)"
              >
                {{ scoped.row.topic }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('nodeDetail.basicInfo.responsibility')"
          width="150"
        >
          <template slot-scope="scoped">
            <el-tooltip
              effect="dark"
              :content="matchUserNickname(scoped.row.assignee, '')"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p
                style="
                  margin: 0;
                  text-align: left;
                  font-size: 18px;
                  font-weight: 400;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  color: rgba(38, 50, 129, 1);
                "
                @mouseenter="visibilityChange($event)"
              >
                {{ matchUserNickname(scoped.row.assignee, "") }}
              </p>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div
        class="vmodelPage"
        style="padding-bottom: 20px; display: flex; justify-content: center"
      >
        <div class="block">
          <el-pagination
            background
            :current-page.sync="currentPageVmodel"
            :page-size="7"
            layout="total, prev, pager, next, jumper"
            :total="pageTotalvmodel2"
            @current-change="handleCurrentChangeVmodel"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  get_pay_vmodel,
  get_expired_vmodel,
} from "@/network/dataChart/index.js";
import { getVmodelNode } from "@/network/charts/index.js";
import { get_v_model_map_details } from "@/network/mindmap/index.js";
import { get_file } from "@/network/baseline/index";
import { getFile } from "@/network/home/index.js";
export default {
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
    chartSources: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      expVmodel: false, // vmode是否过期
      hasVmodel: false, // 是否购买vmodel
      vmodel_table_data: [],
      vmodel_column_list: [],
      isShowTooltip: false,
      paramsVmodel: {},
      dialogVmodel: false, //v-model弹出框
      dialogVmodelTitle: "",
      tableDataVmodel: [],
      pageTotalvmodel2: 1, //wlb
      currentPageVmodel: 1,
    };
  },
  computed: {
    ...mapGetters(["fileType"]),
  },
  watch: {
    currentChartData: {
      handler(newValue, oldValue) {
        this.judgePayForVmodel();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    // 弹出提示去个人主页安装插件
    ejectPrompt() {
      this.$message({
        duration: 0,
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: `<span>${this.$t("certificate.Please")}<a href="${
          window.location.origin
        }/profile/certificate_management?target=vmodel" target="_blank" style="color: blue">${this.$t(
          "certificate.CertificateManagement"
        )}</a>${this.$t("certificate.InstallComponents")}</span>`,
      });
    },
    // 表头判断是否长度超出
    onMouseOver(refName) {
      let parentWidth = this.$refs[refName][0].parentNode.offsetWidth;
      let contentWidth = this.$refs[refName][0].offsetWidth;
      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
    //检测vmodel是否开通付费/过期
    // exp true: 已过期
    // exp false; has false: 未付费
    // exp false; has true: 已付费
    judgePayForVmodel() {
      get_expired_vmodel().then(async (r) => {
        this.expVmodel = r || false;
        if (!r) {
          // 从未开通或开通未过期
          await this.get_vmodel_info(); // 调用数据接口
          get_pay_vmodel(this.get_pid()).then((res) => {
            this.hasVmodel = res || false;
          });
        }
      });
    },
    // 获取v模型数据
    get_vmodel_info() {
      getVmodelNode(this.get_pid(), this.currentChartData.chartId).then(
        (res) => {
          this.vmodel_column_list = [];
          let col_list = [];
          let tab_data = [];
          res.forEach((item) => {
            let obj = {
              prop: item.sourceType,
              label: this.fileType[item.sourceType].name,
            };
            col_list.push(obj);
            let tab_obj = {
              name: this.fileType[item.sourceType].name,
            };
            item.targetTypeRslList.forEach((i) => {
              tab_obj[i.targetType] =
                i.coverage === 0 ? 0 : i.coverage / 100 + "%";
            });
            tab_data.push(tab_obj);
          });
          this.vmodel_column_list = col_list;
          this.vmodel_table_data = tab_data;
        }
      );
    },
    cellStyle(item) {
      if (item.rowIndex + 1 === item.columnIndex) {
        return "background-color: rgba(229, 229, 229, 1);";
      }
      if (item.row[item.column.property] === "100%") {
        return "background-color: rgba(67, 207, 124, 1); color: #fff";
      }
      if (
        (item.row[item.column.property] ||
          item.row[item.column.property] === 0) &&
        item.columnIndex !== 0 &&
        item.row[item.column.property] !== "100%"
      ) {
        // 黄色wlb
        return "background-color: rgba(252, 205, 48, 1); color: #fff;cursor: pointer;";
      }
    },
    // 数据穿透
    // vmodelwlb
    fn_cell_details(row, column, cell, event) {
      this.paramsVmodel = {};
      this.currentPageVmodel = 1;
      let sourceId = this.vmodel_column_list.find((item) => {
        return item.label == row.name;
      });
      this.paramsVmodel.projectId = this.get_pid();
      this.paramsVmodel.sourceFileTypeId = sourceId.prop;
      this.paramsVmodel.targetFileTypeId = column.property;
      this.paramsVmodel.page = this.currentPageVmodel - 1;
      this.paramsVmodel.page_size = 7;
      this.paramsVmodel.data = {
        queryConditions: [],
        mql: "",
      };
      for (let i = 0; i < this.chartSources.length; i++) {
        if (
          this.currentChartData.queryConditionId ==
          this.chartSources[i].queryConditionId
        ) {
          if (
            this.chartSources[i].conditions &&
            this.chartSources[i].conditions.length > 0
          ) {
            this.paramsVmodel.data.queryConditions =
              this.chartSources[i].conditions;
          }
          if (this.chartSources[i].mqlCondition) {
            this.paramsVmodel.data.mql = this.chartSources[i].mqlCondition.mql;
          }
        }
      }
      if (cell.childNodes[0].innerHTML !== "") {
        this.send_get_vmodel_data(this.paramsVmodel);
        this.$nextTick(() => {
          this.dialogVmodel = true;
          if (this.$t("fta.type") == "中文") {
            this.dialogVmodelTitle = `${this.$t("vmodelChart.notAssociated")}${
              column.label
            }${this.$t("vmodelChart.is")}${row.name}${this.$t(
              "vmodelChart.node"
            )}`;
          } else {
            this.dialogVmodelTitle = `${row.name} not linked to ${column.label}`;
          }
        });
      }
    },
    // 获取穿透数据
    async send_get_vmodel_data(params) {
      try {
        let res = await get_v_model_map_details(params);
        this.pageTotalvmodel2 = res.count;
        this.tableDataVmodel = res.nodeList;
      } catch (error) {
        console.log("error", error);
      }
    },
    handleCurrentChangeVmodel(val) {
      this.paramsVmodel.page = Number(val - 1);
      this.send_get_vmodel_data(this.paramsVmodel);
    },
    //节点点击事件
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${this.get_pid()}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
    nodeLink(row) {
      get_file(this.get_pid(), row.fileId).then((res) => {
        this.fileKeyVmodel = res.key;
        row.fileKey = res.key;
        getFile(this.get_pid(), row.fileKey)
          .then((res) => {
            this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
            this.$store.commit("SET_FILE_ID", row.fileId);
            this.$store.commit("SET_FILE_KEY", row.fileKey);
            this.$store.commit("SET_MINDMAP_NAME", row.fileName);
            this.$store.commit("SET_OPEN_STATE", "exist");
            this.$store.commit("SET_FILETYPE_ID", row.fileTypeId);
            //跳转到思维导图
            const { href } = this.$router.resolve({
              path: `/${this.get_pid()}/myMind/${row.fileKey}`,
              query: {
                node_id: row.id,
              },
            });
            window.open(href, "_blank");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
em {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  font-style: normal;
  text-align: left !important;
}
</style>
<style lang="scss">
.vmodel_table {
  margin-right: 20px;
  .el-table {
    line-height: 2px;
  }
  .el-table--border::after,
  .el-table--group::after {
    display: none;
  }
  .el-table td {
    padding: 0;
  }
  .el-table__row > td {
    border-bottom: none;
  }
  .el-table::before {
    height: 0px;
  }
  .el-table--border,
  .el-table--group {
    border: none;
  }

  .el-table th {
    overflow: initial;
    padding: 0;
  }
  .el-table .cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .el-table thead.is-group th {
    background-color: #fff;
    border-right: none;
    border-bottom: none;
  }
  .el-table th > .cell {
    font-size: 14px;
    color: rgba(128, 128, 128, 1);
    font-weight: 400;
  }

  .el-table thead tr:first-of-type th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-top: 5px;
  }
  .el-table thead tr:nth-of-type(2) th:first-of-type {
    border-bottom: none;
    padding: 0;
    padding-bottom: 8px;
  }
  .el-table tbody tr td:first-of-type .cell {
    font-size: 14px;
    color: rgba(128, 128, 128, 1);
    text-align: center;
  }
  .el-table thead tr:first-of-type th:last-of-type > .cell,
  .el-table tbody tr td:last-of-type .cell,
  .el-table__footer-wrapper tbody tr td:first-of-type .cell {
    color: white;
    font-weight: 600;
  }
}
</style>
>
