<template>
  <div>
    <!-- 筛选结果 -->
    <div class="node_list">
      <!-- 节点列表 -->
      <el-table
        :data="nodeData"
        class="clickable-rows"
        :cell-style="changeCellStyle"
        :header-cell-style="{ border: 'none' }"
      >
        <el-table-column
          :label="nodeDataSystem[0].label"
          fixed="left"
          :prop="nodeDataSystem[0].prop"
          :width="nodeDataSystem[0].width"
          :min-width="nodeDataSystem[0].minWidth"
          show-overflow-tooltip
        >
          <template slot="header">
            <span class="sortable-column-header" @click="filekey_sort"
              >Key
              <div class="caret-wrapper">
                <i
                  :class="{
                    'el-icon-caret-top': true,
                    'el-icon-caret-colored': keySort == false,
                  }"
                ></i>
                <i
                  :class="{
                    'el-icon-caret-bottom': true,
                    'el-icon-caret-colored': keySort == true,
                  }"
                ></i>
              </div>
            </span>
          </template>
          <template slot-scope="scope">
            <div style="display: inline-block">
              <span
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="cursor: pointer"
                @click="nodeLink(scope.row)"
                >{{ scope.row.key }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="col in nodeDataSystem.slice(1)"
          :key="col.prop"
          :label="col.label"
          :prop="col.prop"
          :width="col.width"
          :min-width="col.minWidth"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="col.prop === 'fileTypeName'">
              {{ scope.row.fileTypeName }}
            </span>
            <div v-else-if="col.prop === 'name'" style="display: inline-block">
              <span
                v-limits-of-authority="'NODE_DETAIL_READ'"
                style="cursor: pointer"
                @click="nodeLink(scope.row)"
                >{{ scope.row.name }}</span
              >
            </div>
            <span v-else-if="col.prop === 'updatedAt'">
              {{ new Date(scope.row.createdAt).Format("yyyy-MM-dd hh:mm:ss") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          v-for="(col, i) in JSON.parse(currentChartData.frontExtraData)"
          :key="i"
          :label="getColumnInfo(col).label"
          :min-width="getColumnInfo(col).minWidth"
          show-overflow-tooltip
        >
          <template slot="header" slot-scope="scope">
            <div @mouseover="onMouseOver('refName' + scope.$index)">
              <el-tooltip
                :disabled="isShowTooltip"
                :content="getColumnInfo(col).label"
                placement="top"
              >
                <div
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  <span :ref="'refName' + scope.$index">{{
                    getColumnInfo(col).label
                  }}</span>
                </div>
              </el-tooltip>
            </div>
          </template>

          <template slot-scope="scope">
            <span v-if="getColumnInfo(col).isCus && col === 'status'">
              <status-tag
                v-model="scope.row.statusName"
                :style_catogry="'plain'"
                :use="false"
                :status_type="status[scope.row.status].statusCategory"
                :size="'small'"
              >
              </status-tag>
            </span>
            <span v-else-if="getColumnInfo(col).isCus && col === 'created_by'">
              {{ scope.row.createdBy }}
            </span>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页器 -->
      <div class="my-page">
        <el-pagination
          :current-page="page.current"
          background
          small
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_file_condition_by_id,
  get_file_List_datachart,
} from "@/network/nodePage/index.js";
import { get_file } from "@/network/baseline/index";
import { getFile } from "@/network/home/index.js";
import { mapGetters } from "vuex";
import StatusTag from "@/components/statusTag";

export default {
  components: {
    StatusTag,
  },
  props: {
    currentChartData: {
      type: Object,
      default() {
        return {};
      },
    },
    fieldList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      nodeData: [],
      nodeDataSystem: [
        { prop: "key", label: "Key", width: 150, minWidth: 150, fieldId: 2 },
        {
          prop: "fileTypeName",
          label: this.$t("node.table.type"),
          width: 150,
          minWidth: 150,
          fieldId: 1,
        },
        {
          prop: "name",
          label: this.$t("node.table.file_name"),
          width: 300,
          minWidth: 300,
          fieldId: 3,
        },
      ],
      page: {
        current: 1,
        total: 0,
      },
      loading: false,
      keySort: true,
      isShowTooltip: false,
      filterFieldList: [...this.fieldList],
    };
  },
  computed: {
    ...mapGetters([
      "testPlan",
      "version",
      "sprint",
      "baseLine",
      "fileType",
      "status",
      "userAccountId",
    ]),
  },
  watch: {
    currentChartData: {
      handler(newValue, oldValue) {
        this.getTableData();
      },
      deep: true,
    },
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData() {
      this.loading = true;
      get_file_condition_by_id(this.get_pid()).then((r) => {
        let conditions = [];

        r.forEach((ele) => {
          if (ele.createdBy == this.userAccountId) {
            ele.fileQueryConditionDtoList.forEach((item) => {
              if (
                item.queryConditionId == this.currentChartData.queryConditionId
              ) {
                conditions = item.conditions;
              }
            });
          }
        });

        get_file_List_datachart(
          this.keySort,
          this.page.current - 1,
          10,
          this.get_pid(),
          "fileKey",
          conditions,
          ""
        ).then((res) => {
          this.nodeData = res.content;
          this.nodeData.forEach((item) => {
            item.fileTypeName = this.fileType[item.fileTypeId].name;
            item.createdBy = this.matchUserNickname(item.createdBy, "");
            item.assignee = this.matchUserNickname(item.assignee, "");
            item.statusName = this.status[item.status].name;
          });
          this.$set(this.page, "total", res.totalElements);
          this.loading = false;
        });
      });
    },
    handleCurrentChange(val) {
      this.$set(this.page, "current", Number(val));
      this.getTableData();
    },
    //key排序
    filekey_sort() {
      this.keySort = !this.keySort;
      this.getTableData();
    },
    // 跳转文件事件
    nodeLink(row) {
      getFile(this.get_pid(), row.key)
        .then((res) => {
          this.$store.commit("SET_EXIST_MINDMAP", res); //文件内容
          this.$store.commit("SET_FILE_ID", row.id);
          this.$store.commit("SET_FILE_KEY", row.key);
          this.$store.commit("SET_MINDMAP_NAME", row.name);
          this.$store.commit("SET_OPEN_STATE", "exist");
          this.$store.commit("SET_FILETYPE_ID", row.fileTypeId);
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${row.key}`,
          });
          window.open(href, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 筛选结果报表 获取节点数据
    getColumnInfo(fieldId) {
      const newItem = {
        prop: "createdAt",
        extraData: null,
        fieldId: 4,
        fieldName: "createdAt",
        fieldType: "DATE",
        isCus: true,
        isCustom: true,
        label: this.$t("node.table.createdTime"),
        minWidth: 180,
        name: this.$t("node.table.createdTime"),
      };
      if (fieldId == 4) {
        return newItem;
      }
      const systemIds = [1, 2, 3, 4];
      const col = this.fieldList.filter((col) => col.fieldId === fieldId)[0];
      if (!systemIds.includes(col.fieldId)) {
        col.isCus = true;
        col.minWidth = 150;
        col.label = col.name;
      }
      return col;
    },
    // 表头判断是否长度超出
    onMouseOver(refName) {
      let parentWidth = this.$refs[refName][0].parentNode.offsetWidth;
      let contentWidth = this.$refs[refName][0].offsetWidth;
      // 判断是否开启tooltip功能
      if (contentWidth > parentWidth) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },

    changeCellStyle(row, column, rowIndex, columnIndex) {
      if (row.column.label === "Key") {
        return "color: #FFC300;font-weight: 700;border: none;"; // 修改的样式
      } else {
        return "color: #263281;font-weight: 700;border: none;";
      }
    },
    get_user_name(params) {
      let newarr = [];
      typeof params === "object" && params
        ? params.forEach((item) => {
            newarr.push(this.matchUserNickname(item, ""));
          })
        : newarr.push(this.matchUserNickname(params, ""));
      return newarr;
    },
  },
};
</script>

<style lang="scss" scoped>
.node_list {
  height: 100%;
  margin: 0px 20px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  height: fit-content;
}
.clickable-rows {
  background: white;
  overflow-x: auto;
}
.my-page {
  width: 100%;
  text-align: left;
}
</style>
